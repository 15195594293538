import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Row, Table } from 'react-bootstrap';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import "../../../../css/complaintManagement.css";
import "../../../../css/pagination.css"
import { getJudgeComplaints } from '../../../../redux/actions/userActions/userActions';
import { TYPES } from '../../../../redux/types/types';
import JudgeComplaintCanvas from './judgeComplaintCanvas';
import { isLoadingSelector } from '../../../../redux/selectors/statusSelector';
import Loader from '../../../../common-components/Loader';
import { EndPoints } from '../../../../axios/Endpoints';
import { GET, PATCH } from '../../../../axios/Axios';
import ConfirmationModal from '../../../../common-components/confirmationModal';
import { errorSelector } from '../../../../redux/selectors/errorSelector';
import Toaster from '../../../../common-components/Toaster';
import PaginationComponent from '../../../../common-components/Pagination';

const JudgeComplaints = () => {
  const [judgeData, setJudgeData] = useState({judgeComplaints:[],judgeComplaintCount:0});
  const [selectedItem, setSelectedItem] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const handleClose = () => setModalShow(false);
  const [Toster, setToster] = useState(false);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [params, setParams] = useState({
    "page": 1,
    "limit": 10,
  });

  const isLoading = useSelector((state) =>
    isLoadingSelector([TYPES.JUDGE_COMPLAINTS], state)
  );

  const errors = useSelector(
    (state) => errorSelector([TYPES.JUDGE_COMPLAINTS], state),
    shallowEqual
  );

  const handleShow = (item) => {
    setShow(true)
    setSelectedItem(item);
  };

  const handleApprove = async (_id) => {
    const { endpoint, header } = EndPoints.approveComplaints;
    let status = await PATCH(endpoint,{complaintId: _id},header);
    const { res, error } = status;
    if (res.status == 200) {
      setModalShow(false);
      setToster(true);
      dispatch(getJudgeComplaints({setJudgeData,params}));
    } else {
      console.log("error", error);
    }
  }

  const paramsChange = async (key, value) => {
    let obj = { ...params };
    obj[key] = value;
    // if(key=="search")obj["page"] = 1;
    dispatch(getJudgeComplaints({setJudgeData, params:obj}));
    setParams(obj);
  };
 
  useEffect(() => {
    dispatch(getJudgeComplaints({ setJudgeData, params }));
  }, []);

  return (
    <>
      <div>
            <Card className="judgeCard">
              <Card.Body className="table-responsive">
                <Table hover bordered>
                  <thead>
                    <tr>
                      <th className="tableHeader">Complainant's Name</th>
                      <th className="tableHeader">Complainant's Address</th>
                      <th className="tableHeader">Attorney's Name</th>
                      <th className="tableHeader">Attorney's Contact No.</th>
                      <th className="tableHeader">Judge Name</th>
                      <th className="tableHeader">Actions</th>
                    </tr>
                  </thead>
                  <Loader show={isLoading} />
                  {judgeData?.judgeComplaints.map((item, index) => {
                    return (
                      <tbody key={index}>
                        <tr>
                          <td>{item?.name ? item?.name : "-"}</td>
                          <td>{item?.address?item?.address:"-"} <br/>{item?.city ? `,${item?.city}`: ""}{item?.state ? `,${item?.state}` : ""} <br/>
                           <strong>Zipcode:- </strong>{item?.zip ? item?.zip : "-"}</td>
                          <td>{item?.attorneyName ? item?.attorneyName : "-"}</td>
                          <td>{item?.attorneyContact ? `,${item?.attorneyContact}` : ""}</td>
                          <td>{item?.judgeName ? item?.judgeName : "-"}</td>
                          <td className="actiontd">
                            <div style={{alignSelf: "center" }}>
                              <span style={{ color: "#0D99FF",fontSize: "14px", textDecoration: "none", cursor: "pointer" }}
                                onClick={() => { handleShow(item) }}
                              >View more</span></div>
                            <div>
                              <Button variant="success"
                                className='approveBtn'
                                size="sm"
                                onClick={() => {
                                  setModalShow(true);
                                  setSelectedItem(item?._id)
                                }}
                                disabled={item?.aprooved == true}
                              >
                                {item?.aprooved? "Approved" : "Approve"}
                              </Button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    )
                  })}
                </Table>
              </Card.Body>
            </Card>
          <JudgeComplaintCanvas show={show} title={"Judge"} setShow={setShow} data={selectedItem} />
          <ConfirmationModal show={modalShow}
            msg={"Do you want to approve this complaint ?"}
            onHide={() => setModalShow(false)}
            handleClose={handleClose}
            performAction={() => { handleApprove(selectedItem) }}
          />
          {errors[0] ? (
            <Toaster bg="danger" msg={errors[0]} position={"bottom-end"} setToster={Toster} hideToster={() => setToster(false)} />
          ) : null}
      </div>
      <div className='paginationContainer'>
      <PaginationComponent paramsChange={paramsChange.bind(this)} params={params} totalCount={judgeData?.judgeComplaintCount} />
      </div>
    </>
  )
}

export default JudgeComplaints
import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "../css/ForgotPassword.css";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { EndPoints } from "../axios/Endpoints";
import { PATCH } from "../axios/Axios";
import Toaster from "./Toaster";
import Loader from "./Loader";


const UpdatePassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [toaster, setToaster] = useState(false);
  const [loaderState, setLoaderState] = useState(false);
  const [error, setError] = useState({
    email: "",
    currentPassword: "",
    newPassword: "",
    confirmPassword: ""
  });
  const [successMessage, setSuccessMessage] = useState("");

  const validator = (type, value) => {
    switch (type) {
      case "email":
        return /\S+@\S+\.\S+/.test(value); 
      case "password":
        return value.length >= 8 && value.length <= 16; 
      default:
        return false;
    }
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    if (!value) {
      setError((prev) => ({ ...prev, email: "Email cannot be empty" }));
    } else if (!validator("email", value)) {
      setError((prev) => ({ ...prev, email: "Enter a valid email address" }));
    } else {
      setError((prev) => ({ ...prev, email: "" }));
    }
  };

  const handleCurrentPasswordChange = (e) => {
    const value = e.target.value;
    setCurrentPassword(value);

    if (!value) {
      setError((prev) => ({ ...prev, currentPassword: "Password cannot be empty" }));
    } else {
      setError((prev) => ({ ...prev, currentPassword: "" }));
    }
  };

  const handleNewPasswordChange = (e) => {
    const value = e.target.value;
    setNewPassword(value);

    if (!value) {
      setError((prev) => ({ ...prev, newPassword: "Password cannot be empty" }));
    } else if (!validator("password", value)) {
      setError((prev) => ({ ...prev, newPassword: "Password must be between 8 and 16 characters long" }));
    } else {
      setError((prev) => ({ ...prev, newPassword: "" }));
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setConfirmPassword(value);

    if (!value) {
      setError((prev) => ({ ...prev, confirmPassword: "Password cannot be empty" }));
    } else if (value !== newPassword) {
      setError((prev) => ({ ...prev, confirmPassword: "Passwords do not match" }));
    } else {
      setError((prev) => ({ ...prev, confirmPassword: "" }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoaderState(true);
    setError({ email: "", currentPassword: "", newPassword: "", confirmPassword: "" });
    setSuccessMessage("");

    const payload = {
      email: email,
      password: currentPassword,
      newPassword: newPassword,
      confirmPassword: confirmPassword
    };

    const forgotPassword_endpoint = EndPoints.resetPassword.endpoint;

    const response = await PATCH(forgotPassword_endpoint, payload, { "Content-Type": "application/json" });

    if (!response.res) {
      setError((prev) => ({ ...prev, newPassword: response.error.message ?? `${response.error.message}` }));
    } else {
      setSuccessMessage("Password has been updated successfully.");
      setEmail("");
      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");
      setToaster(true);
      setTimeout(() => {
        navigate("/home/dashboard");
      }, 3000);
    }

    setLoaderState(false);
  };

  const isFormValid = email && currentPassword && newPassword && confirmPassword && !error.email && !error.currentPassword && !error.newPassword && !error.confirmPassword;

  return (
    <div className="forgot-password-container">
      <Toaster msg={successMessage} bg="success" setToster={toaster} position="top-end"/>
      <Loader show={loaderState}/>
      <Card className="forgotPasswordCard">
        <Card.Body>
          <ArrowBackIcon className="back-icon" onClick={() => navigate("/home/dashboard")}/>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formEmail">
              <h2 className="heading">Update your password</h2>
              <Form.Control
                className="forgotPassword-input"
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={handleEmailChange}
                style={{
                  border: error.email ? "2px solid red" : "2px solid #EDECE8",
                }}
              />
              {error.email && (
                <span style={{ color: "red", display: "block", marginTop: "5px" }}>
                  {error.email}
                </span>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formCurrentPassword">
              <Form.Control
                className="forgotPassword-input"
                type="password"
                placeholder="Enter current password"
                value={currentPassword}
                onChange={handleCurrentPasswordChange}
                style={{
                  border: error.currentPassword ? "2px solid red" : "2px solid #EDECE8",
                }}
              />
              {error.currentPassword && (
                <span style={{ color: "red", display: "block", marginTop: "5px" }}>
                  {error.currentPassword}
                </span>
              )}
            </Form.Group>
            {error.newPassword && (
                <span style={{ color: "red", display: "block", marginTop: "5px" }}>
                  {error.newPassword}
                </span>
              )}
            <Form.Group className="mb-3" controlId="formNewPassword">
              <Form.Control
                className="forgotPassword-input"
                type="password"
                placeholder="Enter new password"
                value={newPassword}
                onChange={handleNewPasswordChange}
                style={{
                  border: error.newPassword ? "2px solid red" : "2px solid #EDECE8",
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formConfirmPassword">
              <Form.Control
                className="forgotPassword-input"
                type="password"
                placeholder="Confirm new password"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                style={{
                  border: error.confirmPassword ? "2px solid red" : "2px solid #EDECE8",
                }}
              />
              {error.confirmPassword && (
                <span style={{ color: "red", display: "block", marginTop: "5px" }}>
                  {error.confirmPassword}
                </span>
              )}
            </Form.Group>
            {successMessage && (
              <span style={{ color: "green", display: "block", marginBottom: "10px" }}>
                {successMessage}
              </span>
            )}
            <Button
              variant="primary"
              type="submit"
              disabled={!isFormValid}
              style={{
                width: "100%",
                borderRadius: "0.5rem",
                backgroundColor: !isFormValid ? "#CCDDE8" : "#007bff",
                color: !isFormValid ? "#000" : "#fff",
                border: "none",
              }}
            >
              SEND
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default UpdatePassword;

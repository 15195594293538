import { TYPES } from "../../types/types";

// Action Handlers
export const dashboardRequest = () => ({
  type: TYPES.DASHBOARD_REQUEST,
  payload: null,
});

export const dashboardSuccess = () => ({
  type: TYPES.DASHBOARD_SUCCESS,
  payload: null,
});

export const dashboardError = (error) => ({
  type: TYPES.DASHBOARD_ERROR,
  payload: { error },
});
import { TYPES } from "../../types/types";

// Action Handlers
export const policeComplaintRequest = () => ({
  type: TYPES.POLICE_COMPLAINTS_REQUEST,
  payload: null,
});

export const policeComplaintSuccess = () => ({
  type: TYPES.POLICE_COMPLAINTS_SUCCESS,
  payload: null,
});

export const policeComplaintError = (error) => ({
  type: TYPES.POLICE_COMPLAINTS_ERROR,
  payload: { error },
});



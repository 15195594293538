// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  max-width: 100% !important;
}

.nav-container {
  padding: 20px
}

.main-container {
  margin-top: 30px !important;
  flex-direction: column;
  width: 100% !important;
  min-width: 0;
  max-width: 100%;
  padding: 0 2rem 0 16rem !important;
}



.sidebar-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  margin-right: 0 !important;
  margin-left: 0 !important;
  padding-left: 0px !important;
  /* background-color: #151528 !important; */
  background-color: #fff;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  /* border: 2px Solid #f8c537; */
  border-top: none;
  /* min-height: 100vh; */
}
@media screen and (max-width:991px ) and (min-width: 800px) {
  .main-container{
    padding: 0 2rem 0 16rem !important;
  }
}
/* @media only screen and (min-width: 768px) {
  .main-container{
    padding: 0 2rem 0 13rem !important;
  }
} */
@media screen and (max-width:1439px ) and (min-width: 1024px) {
  .main-container{
    padding: 0 2rem 0 16rem !important;
  }
}
@media only screen and (min-width: 1440px){
  .main-container{
    padding: 0 2rem 0 18rem !important;
  }
}
@media only screen and (min-width:1792px) and (max-width: 2048px){
  .main-container{
    padding: 0 2rem 0 21rem !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/css/Home.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;AAC5B;;AAEA;EACE;AACF;;AAEA;EACE,2BAA2B;EAC3B,sBAAsB;EACtB,sBAAsB;EACtB,YAAY;EACZ,eAAe;EACf,kCAAkC;AACpC;;;;AAIA;EACE,eAAe;EACf,MAAM;EACN,SAAS;EACT,OAAO;EACP,0BAA0B;EAC1B,yBAAyB;EACzB,4BAA4B;EAC5B,0CAA0C;EAC1C,sBAAsB;EACtB,uDAAuD;EACvD,+BAA+B;EAC/B,gBAAgB;EAChB,uBAAuB;AACzB;AACA;EACE;IACE,kCAAkC;EACpC;AACF;AACA;;;;GAIG;AACH;EACE;IACE,kCAAkC;EACpC;AACF;AACA;EACE;IACE,kCAAkC;EACpC;AACF;AACA;EACE;IACE,kCAAkC;EACpC;AACF","sourcesContent":[".container {\n  max-width: 100% !important;\n}\n\n.nav-container {\n  padding: 20px\n}\n\n.main-container {\n  margin-top: 30px !important;\n  flex-direction: column;\n  width: 100% !important;\n  min-width: 0;\n  max-width: 100%;\n  padding: 0 2rem 0 16rem !important;\n}\n\n\n\n.sidebar-container {\n  position: fixed;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  margin-right: 0 !important;\n  margin-left: 0 !important;\n  padding-left: 0px !important;\n  /* background-color: #151528 !important; */\n  background-color: #fff;\n  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);\n  /* border: 2px Solid #f8c537; */\n  border-top: none;\n  /* min-height: 100vh; */\n}\n@media screen and (max-width:991px ) and (min-width: 800px) {\n  .main-container{\n    padding: 0 2rem 0 16rem !important;\n  }\n}\n/* @media only screen and (min-width: 768px) {\n  .main-container{\n    padding: 0 2rem 0 13rem !important;\n  }\n} */\n@media screen and (max-width:1439px ) and (min-width: 1024px) {\n  .main-container{\n    padding: 0 2rem 0 16rem !important;\n  }\n}\n@media only screen and (min-width: 1440px){\n  .main-container{\n    padding: 0 2rem 0 18rem !important;\n  }\n}\n@media only screen and (min-width:1792px) and (max-width: 2048px){\n  .main-container{\n    padding: 0 2rem 0 21rem !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setRef } from "../../../axios/Axios";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import "../../../css/dashboard.css"
import AnimatedNum from "../../../common-components/animatedNumber";
import { getDashboardData } from "../../../redux/actions/userActions/userActions";

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dashboardData, setDashboardData] = useState([]);

  useEffect(() => {
    dispatch(getDashboardData({ setDashboardData }));
    setRef(dispatch, navigate)
  }, [])

  return (<div>
    <Row>
            <Col md={6} lg={6}>
              <Card style={{ background: "#eceee4", padding: "50px 15px" }}>
                <Card.Body className="text-center totalUsers">
                  <GroupsOutlinedIcon />
                  <Card.Title>Total Users</Card.Title>
                  <Card.Text>
                    <AnimatedNum value={dashboardData?.totalUsers} />
                  </Card.Text>
                </Card.Body>
              </Card>

            </Col>
            <Col md={6} lg={6}>
              <Card style={{ background: "#eceee4", padding: "50px 15px" }}>
                <Card.Body className="text-center totalUsers">
                  <PersonOutlineOutlinedIcon />
                  <Card.Title>Subscribed Users</Card.Title>
                  <Card.Text>
                    <AnimatedNum value={dashboardData?.totalSubscribedUsers} />
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
    </Row>
  </div>
  )
};

export default Dashboard;

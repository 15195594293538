import { TYPES } from "../../types/types";

// Action Handlers
export const subscriptionRequest = () => ({
  type: TYPES.SUBSCRIPTION_REQUEST,
  payload: null,
});

export const subscriptionSuccess = () => ({
  type: TYPES.SUBSCRIPTION_SUCCESS,
  payload: null,
});

export const subscriptionError = (error) => ({
  type: TYPES.SUBSCRIPTION_ERROR,
  payload: { error },
});



import { Button, Grid, Input } from '@mui/material';
import React, { useState } from 'react';

import icon from "./../assets/Logo.png"
import axios from 'axios';
import { Host } from '../../axios/Host';

const DeleteAccount = () => {
  const [emailDeleteValue, setEmailDeleteValue] = useState("");
  const [emailDeleted, setEmailDeleted] = useState(false);
  const deleteEmail = async () => {
    try {
      console.log(emailDeleteValue);
      await axios.delete(`${Host}user/deleteAccount`, {
        data: {email: emailDeleteValue}
      })
      setEmailDeleted(true);
    } catch (error) {
      console.log({error});
    }
  }
  return (
    <div>
      <Grid container sx={{padding: "50px", background: "#ffd3d3"}}>
        <Grid container>
          <Grid md={5}>
            <div>
              <img width="50px" src={icon}/> <span>Equal Justice Project</span>
            </div>
          </Grid>
          <Grid md={7}>
            <h4>Delete account</h4>
          </Grid>
        </Grid>
      </Grid>
       <Grid container>
        <Grid md={12} sx={{padding: "10% 10% 52px 10%"}}>
          <h2>Account Deletion</h2>
          <h5>Hi there,</h5>
          <p>
            If you’re considering bidding farewell to Iwitness, we understand it’s a tough decision. Should you choose to delete your account, you can do so directly from the app. Rest assured, your privacy is of utmost importance to us. If you wish, we can also erase all data associated with your account, including the information used during your initial installation and sign-in process.
          </p>
          <p>
            To initiate the process, could you please provide your email address? This will allow us to delete your registered email address.
          </p>
          <p>
            Before you go, we’d love to hear any feedback you might have. Your insights help us improve and better serve our users. If there's anything we can do to address your concerns or improve your experience, please leave a review on app-store and play-store.
          </p>
          <p>
            Thank you for being a part of the Iwitness community. We hope to see you again in the future. If you ever decide to return, we’ll be here to welcome you back with open arms.
          </p>
        </Grid>
        <Grid sm={12} md={12} sx={{textAlign: "center", marginBottom: "20px"}}>
          {
            emailDeleted ? 
              <div style={{padding: "0px 200px",color: "#4949f2"}}>Account deleted successfully.</div> :
              <div>
                <div >
                  <Input sx={{marginRight: "10px", marginBottom: "10px"}} placeholder='Enter you email' onChange={(e) => setEmailDeleteValue(e.target.value)} />
                  <Button variant='contained' color='error' onClick={deleteEmail}>Delete Account</Button>
                </div>
              </div>
          }
        </Grid>
      </Grid>
    </div>
  );
}

export default DeleteAccount;

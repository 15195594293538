import React, { useEffect, useRef, useState } from 'react'
import Peer from "peerjs";

const UserLiveStream = () => {
  const [videoStream, setvideoStream] = useState(null)
  const videoRef = useRef(null)

  useEffect(() => {
    // get video/voice stream
    navigator.mediaDevices.getUserMedia({
      video: true,
      audio: true
    }).then(stream => {
      gotMedia(stream)
      let video = videoRef.current;
      video.srcObject = stream;
      video.play();
    }).catch((e) => {console.log("err",e); })
    
  }, [])

  const gotMedia = (stream) => {
    var peer = new Peer("A1B6")
    debugger
    peer.on("call", (call) => {
      debugger
      call.answer(stream)
    });
  }


  return (
    <div>UserLiveStream
      <div style={{border: "4 red"}}>
        video
          <video height={"200px"} width={"200px"} ref={videoRef}></video>
      </div>
    </div>
    
  )
}

export default UserLiveStream
import React from 'react'
import { Form, Offcanvas } from 'react-bootstrap';
import "../../../../css/complaintCanvas.css"

const JudgeComplaintCanvas = ({ data, show, title, setShow }) => {
    const handleClose = () => setShow(false);
    return (
        <>
            <div>
                <Offcanvas show={show} onHide={handleClose}>
                    <Offcanvas.Header className="titleDetails" closeButton>
                        <Offcanvas.Title>
                            {`${title} Complaint Form Details`}
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Form>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <p className="labelPara">Complainant's Name :</p>
                                <Form.Control
                                    readOnly
                                    value={data?.name}>
                                </Form.Control>
                                <p className="labelPara">Complainant's Address :</p>
                                <Form.Control
                                    readOnly
                                    value={data?.address}>
                                </Form.Control>
                                <p className="labelPara">Complainant's Zipcode :</p>
                                <Form.Control
                                    readOnly
                                    value={data?.zip}>
                                </Form.Control>
                                <p className="labelPara">Attorney's Name :</p>
                                <Form.Control
                                    readOnly
                                    value={data?.attorneyName}>
                                </Form.Control>
                                <p className="labelPara">Atttorney's contact no. :</p>
                                <Form.Control
                                    readOnly
                                    value={data?.attorneyContact}>
                                </Form.Control>
                                <p className="labelPara">Judge Name :</p>
                                <Form.Control
                                    readOnly
                                    value={data?.judgeName}>
                                </Form.Control>
                                <p className="labelPara">Court :</p>
                                <Form.Control
                                    readOnly
                                    value={data?.court}>
                                </Form.Control>
                                <p className="labelPara">Country :</p>
                                <Form.Control
                                    readOnly
                                    value={data?.country}>
                                </Form.Control>
                                <p className="labelPara">Case Name and Number :</p>
                                <Form.Control
                                    readOnly
                                    value={data?.caseNumber ? data?.caseNumber : "-"}>
                                </Form.Control>
                                <p className="labelPara">
                                    Description :
                                </p>
                                <Form.Control
                                    as="textarea"
                                    rows={3} readonly
                                    value={data?.des ? data?.des : "-"}
                                >
                                </Form.Control>
                            </Form.Group>
                        </Form>
                    </Offcanvas.Body>
                </Offcanvas>
            </div>
        </>

    )
}

export default JudgeComplaintCanvas;
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const ConfirmationModal = (props)=> {

  return (
    <> 
     <Modal
        show={props.show}
        onHide={props.onHide}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header style={{border:"none"}} closeButton>
        </Modal.Header>
        <Modal.Body 
        style={{
          paddingTop:"0px",
          paddingBottom : "0px"
        }}
        >
         {props.msg}
        </Modal.Body>
        <Modal.Footer style={{border:"none"}}>
          <Button variant="secondary" onClick={props.handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={()=>{props.performAction()}}>Yes</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ConfirmationModal;
import { TYPES } from "../../types/types";

// Action Handlers
export const lawyerComplaintRequest = () => ({
  type: TYPES.LAWYER_COMPLAINTS_REQUEST,
  payload: null,
});

export const lawyerComplaintSuccess = () => ({
  type: TYPES.LAWYER_COMPLAINTS_SUCCESS,
  payload: null,
});

export const lawyerComplaintError = (error) => ({
  type: TYPES.LAWYER_COMPLAINTS_ERROR,
  payload: { error },
});



import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Table } from 'react-bootstrap';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Loader from '../../../../common-components/Loader';
import "../../../../css/subscriptionManagement.css";
import { getAllSubscriptions } from '../../../../redux/actions/userActions/userActions';
import { errorSelector } from '../../../../redux/selectors/errorSelector';
import { isLoadingSelector } from '../../../../redux/selectors/statusSelector';
import { TYPES } from '../../../../redux/types/types';
const SubscriptionManagement = () => {
    const [plansData, setPlansData] = useState([]);
    const dispatch = useDispatch();

    const isLoading = useSelector((state) =>
        isLoadingSelector([TYPES.SUBSCRIPTION], state)
    );

    const errors = useSelector(
        (state) => errorSelector([TYPES.SUBSCRIPTION], state),
        shallowEqual
    );

    useEffect(() => {
        dispatch(getAllSubscriptions({ setPlansData }))
    }, []);
    return (
        <div>
            <h4 style={{ marginBottom: "30px" }}>Subscription Management</h4>
            <Row>
                <Loader show={isLoading} />
                {
                    plansData.map((item, index) => {
                        return (
                            <Col className='cardContainer' md={12} lg={4}>
                                <Card key={index} className="subsTableCard">
                                    <Card.Body>
                                        <div>
                                            <h4>{item?.planName}</h4>
                                        </div>
                                        <div>
                                            <p style={{ color: "#6e6565" }}>{item?.desc}</p>
                                        </div>
                                        <div style={{ color: "red", fontWeight: "500" }}>
                                            <p>{`Private Investigator for ${item?.privateInvTime}`}</p>
                                        </div>
                                                <h5>Services :</h5>
                                                {
                                                    item?.services.map((innerItem, innerIndex) => {
                                                        return (<div key={innerIndex} style={{ justifyContent: "space-between", display: "flex" }}>
                                                            <p>{innerItem?.serviceName}</p>
                                                        </div>
                                                        )
                                                    })
                                                }

                                        <div style={{ justifyContent: "space-between", display: "flex"}}>
                                            <p style={{color : "red",fontWeight:"500",fontSize:"20px"}}>Amount : <strong style={{color:"gold"}}>{`$ ${item?.price}`}</strong> </p>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        )
                    })
                }
            </Row>

        </div>
    )
}

export default SubscriptionManagement;
import { TYPES } from "../../types/types";

// Action Handlers
export const dropRequest = () => ({
  type: TYPES.DROP_REQUEST,
  payload: null,
});

export const dropSuccess = () => ({
  type: TYPES.DROP_SUCCESS,
  payload: null,
});

export const dropError = (error) => ({
  type: TYPES.DROP_ERROR,
  payload: { error },
});



// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.paginationContainer {
    justify-content: center;
    align-items: center;
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    /* border: 2px solid grey; */
    /* border-top: none; */
    margin-bottom: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/css/pagination.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,iBAAiB;IACjB,oBAAoB;IACpB,4BAA4B;IAC5B,sBAAsB;IACtB,mBAAmB;AACvB","sourcesContent":[".paginationContainer {\n    justify-content: center;\n    align-items: center;\n    display: flex;\n    padding-top: 10px;\n    padding-bottom: 10px;\n    /* border: 2px solid grey; */\n    /* border-top: none; */\n    margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { TYPES } from "../../types/types";

// Action Handlers
export const judgeComplaintRequest = () => ({
  type: TYPES.JUDGE_COMPLAINTS_REQUEST,
  payload: null,
});

export const judgeComplaintSuccess = () => ({
  type: TYPES.JUDGE_COMPLAINTS_SUCCESS,
  payload: null,
});

export const judgeComplaintError = (error) => ({
  type: TYPES.JUDGE_COMPLAINTS_ERROR,
  payload: { error },
});



import * as React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
const PaginationComponent = ({ paramsChange, params, totalCount }) => {
    const nPages = Math.ceil(totalCount / params.limit)
    // const pageNumbers = [...Array(nPages + 1).keys()].slice(1)
    const handleChange = (e, page) => {
        paramsChange("page", page);
    };
    return (
        <div>
            <Stack spacing={2}>
                <Pagination count={nPages} page={params.page} onChange={handleChange} color="primary" />
            </Stack>
        </div>
    );
}

export default PaginationComponent;
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../css/Login.css";
import { validator, Validator } from "../../utils/Validation";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { isLoadingSelector } from "../../redux/selectors/statusSelector";
import { TYPES } from "../../redux/types/types";
import {
  clearError,
  userLogin,
} from "../../redux/actions/userActions/userActions";
import Logo from "../assets/Logo.png";
import Logo1 from "../assets/illustration_login.png";
import { errorSelector } from "../../redux/selectors/errorSelector";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Container, Col, Row } from "react-bootstrap";
import { setRef } from "../../axios/Axios";

const Login = () => {
  const [body, setBody] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState({
    email: "",
    password: "",
  });
  const [showPass, setShowPass] = useState("password");
  const [emailValid, setEmailValid] = useState(false);
  const navigate = useNavigate();
  const isLoading = useSelector((state) =>
    isLoadingSelector([TYPES.LOGIN], state)
  );
  const errors = useSelector(
    (state) => errorSelector([TYPES.LOGIN], state),
    shallowEqual
  );
  const dispatch = useDispatch();
  const onChangeText = async (key, value) => {
    let obj = { ...body };
    obj[key] = value;
    setBody(obj);
  };
  const handleSubmit = () => {
    dispatch(
      userLogin(
        { ...body, email: body?.email.trim().toLocaleLowerCase() },
        () => {
          navigate("/home/dashboard");
        }
      )
    );
  };
  useEffect(() => {
    setRef(dispatch, navigate)
    dispatch(clearError());
  }, []);

  const checkEmpty = async ({ key, msg, value }) => {
    let obj = { ...error };
    let common = "cannot be empty";
    switch (key) {
      case "email":
        obj["email"] = !value
          ? `Email  ${common}`
          : !validator("email", value.trim())
            ? "Please enter the valid Email address"
            : msg;

        break;
      case "password":
        obj["password"] = !value
          ? `Password ${common}`
          : !validator("password", value)
            ? "Password must be atleast Min. 8 and Max. 16 characters long."
            : msg;
        break;
    }

    setError(obj);
  };

  const validation =
    Object.values(body).filter((item) => item).length == 2 &&
    validator("email", body.email.trim()) &&
    validator("password", body.password);

  return (
    <Container fluid className="div-container">
      <Row className="row-Container">
        <Col lg={6} xl={4} md={5} className="loginCardContainer1">
          <Card className="login-card1">
            <img src={Logo} alt="Login_Logo" className="loginLogo"></img>
            <Card.Body>
              <Card.Text>
                <h1>Hi, Welcome Back</h1>
              </Card.Text>
              <Card.Title>
                <img src={Logo1} alt="Login_Logo1" className="loginLogo1"></img>
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6} md={7} className="loginCardContainer">
          <Card style={{ zIndex: "20" }} className="login-card">
            <Card.Body style={{ alignItems: "flex-start" }}>
              <h2 className="heading1">
                Sign in to Iwitness
              </h2>
              <p className="headingPara">Enter your details below.</p>
              <Card.Text>
                <Form>
                  <Form.Group className="mb-2" controlId="formBasicEmail">
                    <Form.Control
                      type="email"
                      className="Login-inputFields"
                      value={body.email}
                      onChange={(e) => {
                        setEmailValid(true);
                        onChangeText("email", e.target.value);
                        checkEmpty({
                          key: "email",
                          msg: "",
                          value: e.target.value,
                        });
                        dispatch(clearError())
                      }}
                      onBlur={() => {
                        checkEmpty({
                          key: "email",
                          msg: "",
                          value: body.email,
                        });
                      }}
                      style={{
                        border: error.email.length ? "2px solid red" : "2px solid #EDECE8",
                      }}
                      placeholder="Enter email"
                    />
                    {error.email ? (
                      <span style={{ color: "red", display: "flex" }}>
                        {error.email}
                      </span>
                    ) : null}
                  </Form.Group>
                  <Form.Group className="mb-2" controlId="formBasicPassword">
                    <div className="passwordContainer">
                      <Form.Control
                        type={showPass}
                        className="Login-inputFields"
                        placeholder="Password"
                        value={body.password}
                        autoComplete="none"
                        onChange={(e) => {
                          onChangeText("password", e.target.value);
                          checkEmpty({
                            key: "password",
                            msg: "",
                            value: e.target.value,
                          });
                          dispatch(clearError())
                        }}
                        onBlur={() => {
                          checkEmpty({
                            key: "password",
                            msg: "",
                            value: body.password,
                          });
                        }}
                        style={{
                          border: error?.password ? "2px solid red" : "2px solid #EDECE8",
                        }}
                      />
                      {showPass == "text" ? (
                        <VisibilityIcon
                          onClick={() => {
                            setShowPass("password");
                          }}
                        />
                      ) : (
                        <VisibilityOffIcon
                          onClick={() => {
                            setShowPass("text");
                          }}
                        />
                      )}
                    </div>

                    {error.password ? (
                      <span style={{ color: "red", display: "flex" }}>
                        {error.password}
                      </span>
                    ) : null}
                  </Form.Group>

                  {errors[0] ? (
                    <span style={{ color: "red" }}>{errors[0]}</span>
                  ) : null}
                  <Button
                    variant="success"
                    type="submit"
                    // className="signin-btn"
                    style={{
                      width: "100%",
                      borderRadius: "0.5rem",
                      backgroundColor: !validation ? "#CCDDE8" : "red",
                      color: !validation ? "#000" : "#fff",
                      border: "none",
                    }}
                    disabled={isLoading ? isLoading : !validation}
                    onClick={() => handleSubmit()}
                  >
                    Login
                  </Button>
                </Form>
                <div style={{ justifyContent: "center", display: "flex" }}>
                  <div
                    style={{
                      height: 1,
                      width: "40%",
                      alignSelf: "center",
                      backgroundColor: "#000",
                      margin: "11px 6px 0px 0",
                      float: "left",
                    }}
                  />
                  <div
                    style={{
                      width: "20%",
                      float: "left",
                      textAlign: "center",
                      color: "#000",
                      lineHeight: "50px",
                    }}
                  >OR</div>
                  <div
                    style={{
                      height: 1,
                      width: "40%",
                      alignSelf: "center",
                      backgroundColor: "#000",
                      margin: "11px 6px 0px 0",
                      float: "left",
                    }}
                  />
                </div>
    
                <div style={{ justifyContent: "center", display: "flex", cursor: "pointer"}}>
                  <span
                    className="forgotPassLink"
                    onClick={() => navigate("/forgotPassword")}
                  >
                    Forgot Password?
                  </span>
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;

// Template

// const host_name = "localhost:3010";
// const newLocal = `http://${host_name}`;
// export const Hostname = host_name;
// export const Host = newLocal;

const host_name = "iwitness.policeabuse.com/api";
// temp host name
// const host_name = "https://c799-182-72-173-67.ngrok.io"
const newLocal = `https://${host_name}/`;
// export const Hostname = host_name;
export const Host = newLocal;

//temp

// export const Host = host_name;

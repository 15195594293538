import React from 'react'
import { Button, Form, Offcanvas } from 'react-bootstrap';
import "../../../../css/complaintCanvas.css"

const LawyerComplaintCanvas = ({ data, show, title, setShow }) => {
    const handleClose = () => setShow(false);
    return (
        <>
            <div>
                <Offcanvas show={show} onHide={handleClose}>
                    <Offcanvas.Header className="titleDetails" closeButton>
                        <Offcanvas.Title>
                            {`${title} Complaint Form Details`}
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Form>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <p className="labelPara">Complainant's Name :</p>
                                <Form.Control
                                    readOnly
                                    value={data?.name}>
                                </Form.Control>
                                <p className="labelPara">Complainant's Email :</p>
                                <Form.Control
                                    readOnly
                                    value={data?.compPersonEmail}>
                                </Form.Control>
                                <p className="labelPara">Complainant's Contact No. :</p>
                                <Form.Control
                                    readOnly
                                    value={data?.contactNumber}>
                                </Form.Control>
                                <p className="labelPara">Complainant's Address :</p>
                                <Form.Control
                                    readOnly
                                    value={data?.address ? data?.address : "-"}>
                                </Form.Control>
                                <p className="labelPara">Complainant's City :</p>
                                <Form.Control
                                    readOnly
                                    value={data?.city ? data?.city : "-"}>
                                </Form.Control>
                                <p className="labelPara">
                                    Complainant's State :
                                </p>
                                <Form.Control
                                    readonly
                                    value={data?.state ? data?.state : "-"}
                                >
                                </Form.Control>
                                <p className="labelPara">Attorney's Name :</p>
                                <Form.Control
                                    readOnly
                                    value={data?.attorney?.name ? data?.attorney?.name : "-"}>
                                </Form.Control>
                                <p className="labelPara">
                                    Attorney's Address :
                                </p>
                                <Form.Control
                                    readonly
                                    value={data?.attorney?.address ? data?.attorney?.address : "-"}
                                >
                                </Form.Control>
                                <p className="labelPara">Attorney's City :</p>
                                <Form.Control
                                    readOnly
                                    value={data?.attorney?.city ? data?.attorney?.city : "-"}>
                                </Form.Control>
                                <p className="labelPara">Attorney's State :</p>
                                <Form.Control
                                    readOnly
                                    value={data?.attorney?.state ? data?.attorney?.state : "-"}>
                                </Form.Control>
                                <p className="labelPara">Attorney's Zipcode :</p>
                                <Form.Control
                                    readOnly
                                    value={data?.attorney?.zip ? data?.zip : "-"}>
                                </Form.Control>
                                <p className="labelPara">
                                    Attorney's Contact No. :
                                </p>
                                <Form.Control
                                    readonly
                                    value={data?.attorney?.contactNumber ? data?.attorney?.contactNumber : "-"}
                                >
                                </Form.Control>
                                <p className="labelPara">
                                    Complainant's Age :
                                </p>
                                <Form.Control
                                    readonly
                                    value={data?.yourAge ? data?.yourAge : "-"}
                                >
                                </Form.Control>
                                <p className="labelPara">
                                    Attorney's Bar License Number :
                                </p>
                                <Form.Control
                                    readonly
                                    value={data?.attorney?.barLiscenceNo ? data?.attorney?.barLiscenceNo : "-"}
                                >
                                </Form.Control>
                                <p className="labelPara">
                                    State :
                                </p>
                                <Form.Control
                                    rows={3} readonly
                                    value={data?.state ? data?.state : "-"}
                                >
                                </Form.Control>
                                <p className="labelPara">
                                    Previous Complaints :
                                </p>
                                <Form.Control
                                    readonly
                                    value={data?.attorney?.prevComplaint == true ? "Yes" : "No"}
                                >
                                </Form.Control>
                                <p className="labelPara">
                                    Employed the Attorney? :
                                </p>
                                <Form.Control
                                    readonly
                                    value={data?.attorney?.isAttorneyEmploy == true ? "Yes" : "No"}
                                >
                                </Form.Control>
                                <p className="labelPara">
                                    Description :
                                </p>
                                <Form.Control
                                    as="textarea"
                                    rows={3} readonly
                                    value={data?.des ? data?.des : "-"}
                                >
                                </Form.Control>
                            </Form.Group>
                        </Form>
                    </Offcanvas.Body>
                </Offcanvas>
            </div>
        </>

    )
}

export default LawyerComplaintCanvas;
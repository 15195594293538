import { useEffect, useState } from 'react';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
// import Logo from "../components/assets/Logo.svg"

const Toaster = ({ msg, bg, position,setMsg, setToster, hideToster }) => {
    const [show, setShow] = useState(setToster);
    const headerTitle = {
        'success': 'Success',
        'danger': 'Error',
        'warning': "Warning",
        'info': 'Info',
    };
    useEffect(() => {
        setShow(setToster)
    }, [setToster])


    return (
        <>
            <ToastContainer className="p-1" position={position}>
                <Toast
                    onClose={() => {
                        setShow(false)
                        hideToster()
                        if (setMsg) setMsg("")
                    }}
                    show={show}
                    delay={3000}
                    autohide
                    bg={bg}

                >
                    <Toast.Header>

                        <strong className="me-auto">{headerTitle[bg]}</strong>

                    </Toast.Header>
                    <Toast.Body style={{ fontSize: "15px", color: "#fff" }}>{msg}</Toast.Body>
                </Toast>
            </ToastContainer>
        </>
    );
}

export default Toaster;
import React, { useEffect, useState } from 'react'
import { Button, Table } from 'react-bootstrap'
import "../../../../css/chatUserList.css";
import ChatModal from './chatModal';
import socketIO from "socket.io-client";
import { Host } from '../../../../axios/Host';
import moment from "moment/moment";
import storage from 'redux-persist/lib/storage';
import Loader from '../../../../common-components/Loader';

const ChatUsersList = () => {
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
  const [socketConn, setSocketConn] = useState(null);
  const [userData, setUserData] = useState([]);
  const [selectedRoomId, setSelectedRoomId] = useState(null);
  const [clientName, setClientName] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  let socket = null


  function handleShow(fullscreen, roomId, clientName) {
    setSelectedRoomId(roomId);
    setClientName(clientName)
    socketConn.emit('adminJoinRoom', { roomId });
    setFullscreen(fullscreen);
    setShow(true);
  }

  const socket_open = async () => {
    let Token = await storage.getItem("Token");
    socket = await socketIO("https://iwitness.policeabuse.com", {
      extraHeaders: {
        "x-access-token": Token
      }
    });
    setSocketConn(socket)
    getInfo()
  }
  const getInfo = async () => {
    socket.emit('getAllRooms', {});
    socket.on("allRooms", (data) => {
      setIsLoading(false);    
      setUserData(data);
    }
    );
  }
  useEffect(() => {
    socket_open();
  }, [])
  return (
    <>
    <div className="chatCard">

      {userData.length ?
      <Table bordered hover>
        <thead>
          <tr>
            <th>User</th>
            <th>Requested Time</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          { userData.map((item, index) => {
            return (
              <tr key={index}>
                <td>{item?.clientName}</td>
                <td>{moment(item.createdAt).format("MM-DD-YYYY")}</td>
                <td><Button size="sm" style={{ background: "red", border: "none", color: "#fff" }} onClick={() => handleShow(true, item?.roomId, item?.clientName)}>Start Chat</Button></td>
              </tr>
            )
          })
            
          }
            <Loader show={isLoading} />

        </tbody>
      </Table>
      :
      <p style={{ color: "red",fontWeight:"600",fontSize:"18px",paddingLeft:"10px",textAlign:"center" }}>Looks like no one is available for chat right now .</p>}
      {show ? <ChatModal fullscreen={fullscreen} setShow={setShow} show={show} socket={socketConn} selectedRoomId={selectedRoomId} clientName={clientName} /> : null}
    </div>
    </>
  )

}

export default ChatUsersList
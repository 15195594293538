import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Dashboard from "./pannelComponents/dashboard";
import { Col } from "react-bootstrap";
import Filemanagement from "./pannelComponents/fileManagement/fileManagement";
import DropDownManagement from "./pannelComponents/dropDownManagement";
import ComplaintManagement from "./pannelComponents/complaintManagement/complaintManagement";
import SubscriptionManagement from "./pannelComponents/subscriptionManagement/subscriptionManagement";
import ChatUsersList from "./pannelComponents/Chat/chatUsersList";
import ChatModal from "./pannelComponents/Chat/chatModal";
import StreamUserList from "./pannelComponents/liveStream/streamUserList";

const Pannel = (props) => {
  return (
    <Col>
      <Routes>
        <Route path="/dashboard" element={<Dashboard />} />
        {/* <Route path="/userlist" element={<Usermanagement />} /> */}
        <Route path="/fileManagement" element={<Filemanagement />} />
        <Route path="/dropDownManagement" element={<DropDownManagement />} />
        <Route path="/complaintManagement" element={<ComplaintManagement />} />
        <Route path="/subscriptionManagement" element={<SubscriptionManagement />} />
        <Route path="/chatUsersList" element={<ChatUsersList />} />
        <Route path="/chat" element={<ChatModal />} />
        <Route path="/liveStream" element={<StreamUserList/>} />
      </Routes>
    </Col>
  );
};

export default Pannel;

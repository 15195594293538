import React from "react";
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import "../css/loader.css";
const Loader = ({ show, ...props}) => {
    return (
        <Modal
            show={show}
            backdrop="static"
            keyboard={false}
            className="Loader-modal"
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >

            <Modal.Body>
                <Spinner animation="border" variant="info" />
            </Modal.Body>

        </Modal>
    )
}
export default Loader;
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';

import { DELETE, GET } from '../../../../axios/Axios';
import { EndPoints } from '../../../../axios/Endpoints';
import ConfirmationModal from '../../../../common-components/confirmationModal';
import Loader from '../../../../common-components/Loader';
import Toaster from '../../../../common-components/Toaster';
import { getAllAudios } from '../../../../redux/actions/userActions/userActions';
import { errorSelector } from '../../../../redux/selectors/errorSelector';
import { isLoadingSelector } from '../../../../redux/selectors/statusSelector';
import { TYPES } from '../../../../redux/types/types';
import "../../../../css/audioManagement.css";
import "../../../../css/pagination.css"
;
import AudioFileIcon from '@mui/icons-material/AudioFile';
import PaginationComponent from '../../../../common-components/Pagination';

const AudioManagement = () => {
  const [audioData, setAudioData] = useState({allVideos:[]});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modalShowDelte, setModalShowDelte] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const handleClose = () => setModalShow(false);
  const handleShow = () => setModalShow(true);
  const handleAudioDelClose = () => setModalShowDelte(false);
  const [selectedUid, setSelectedUid] = useState(null)
  const [Toster, setToster] = useState(false);
  const [params, setParams] = useState({
    "page": 1,
    "limit": 10,
  });

  const isLoading = useSelector((state) =>
    isLoadingSelector([TYPES.AUDIO], state)
  );
  const errors = useSelector(
    (state) => errorSelector([TYPES.AUDIO], state),
    shallowEqual
  );

  const handleId = async (id) => {
    let arr = await [...audioData.allVideos];
    let index = await arr.findIndex(item => item._id == id);
    arr[index].isApproved = true;
    setAudioData({...audioData,allVideos:arr});
  }

  const handleApprove = async (id) => {
    const { endpoint, header } = EndPoints.approvedVideos;
    let status = await GET(endpoint, header, { vid: id });
    const { res, error } = status;
    if (res.status == 200) {
      setModalShow(false);
      setToster(true)
      handleId(id);
    } else {
      console.log("error", error);
    }
  }
  const handleDelteVideo = async (id) => {
    const { endpoint, header } = EndPoints.deleteVideo;
    let status = await DELETE(endpoint, header, { vid: id });
    const { res, error } = status;
    if (res.status == 200) {
        setModalShowDelte(false);
        setToster(true)
        handleId(id);
      dispatch(getAllAudios({ setAudioData, params }));
    } else {
        console.log("error", error);
    }
}
  const paramsChange = async (key, value) => {
    let obj = { ...params };
    obj[key] = value;
    // if(key=="search")obj["page"] = 1;
    setAudioData([]);
    dispatch(getAllAudios({setAudioData, params:obj}));
    setParams(obj);
  };

  useEffect(() => {
    dispatch(getAllAudios({ setAudioData, params }));
  }, []);

  return (
    <div>
      <Row className='audioCardContainer'>
        <Loader show={isLoading} />
        <>
          {
           isLoading?null:audioData?.allVideos?.map((item, index) => {
              return (
                <Col className="audCol" md={8} lg={6} xxl={4}>
                  <Card key={index} className="audioCard">
                  <p style ={{alignSelf:"flex-start",background: "rgb(0,0,0,0.25)",width: "100%",position: "absolute",paddingLeft:"5px"}}>{item?.uploadedBy}</p>
                    <AudioFileIcon style={{color : "red",fontSize:"100px",marginBottom:"10px",alignSelf:"center"}} />
                    <audio style={{ width: '100%', height: '20px' }} controls src={item?.audioUrl}>
                      <track src="captions_en.vtt" kind="captions" label="english_captions" />
                    </audio>
                    <Card.Body style={{
                      justifyContent: "space-between", display: "flex", alignItems: "center", padding: "10px 5px",
                    }}>
                      <div>
                        <p style={{ marginBottom: "0" }}> Likes : {item?.likesCount}</p>
                      </div>0
                      <div>
                        <p style={{ marginBottom: "0" }}> Views : {item?.viewsCount}</p>
                      </div>
                      <Button
                        size="sm"
                        className="audiosCardBtn"
                        onClick={() => {
                          setModalShow(true);
                          setSelectedUid(item?._id)
                        }}
                        disabled={item?.isApproved == true}
                      >
                        {item?.isApproved ? "Approved" : "Approve"}
                      </Button>
                      <div>
                          <DeleteIcon style={{color: "red", cursor: "pointer"}} onClick={() => {setModalShowDelte(true); setSelectedUid(item._id)} }/>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              )
            }
            )

          }
          <ConfirmationModal show={modalShowDelte}
              msg={"Do you want to delete this audio ?"}
              onHide={() => setModalShowDelte(false)}
              handleClose={handleAudioDelClose}
              performAction={() => { handleDelteVideo(selectedUid) }}
          />
          <ConfirmationModal show={modalShow}
            msg={"Do you want to approve this audio ?"}
            onHide={() => setModalShow(false)}
            handleClose={handleClose}
            performAction={() => { handleApprove(selectedUid) }}
          />
        </>
        {errors[0] ? (
          <Toaster bg="danger" msg={errors[0]} position={"bottom-end"} setToster={Toster} hideToster={() => setToster(false)} />
        ) : null}

        {/* {errorsProfile[0] ? (
            <Toaster
                bg="danger"
                msg={errorsProfile[0]}
                position={"bottom-end"}
            />
        ) : null} */}
      </Row>
      <div className='paginationContainer'>
      <PaginationComponent paramsChange={paramsChange.bind(this)} params={params} totalCount={audioData?.audioCount} />
      </div>
    </div>
  )
}

export default AudioManagement
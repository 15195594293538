import React, { memo, useEffect, useRef, useState } from 'react'
import "../../../../css/chatModal.css";
import { Alert, Button, Card, Form, Modal } from 'react-bootstrap';
import "../../../../css/chatModal.css";
import SendIcon from '@mui/icons-material/Send';

const ChatBody = ({ socket, selectedRoomId }) => {
    const [chatMsg, setChatMsg] = useState([]);
    const [message, setMessage] = useState("");
    const [disableInput, setDisabledInput] = useState(false);
    const [userLeft, setUserLeft] = useState(false);
    const lastMessageRef = useRef(null);

    const handleMessage = (e) => {
        if (message.trim().length == 0) return
        socket.emit("adminMessage", { roomId: selectedRoomId, message: message.trim() });
        setMessage("")
    }
    const readmsg = async () => {
        socket.emit("adminJoinRoom", { roomId: selectedRoomId })
        socket.on("userLeftRoom", (data) => {
            setDisabledInput(true);
            setUserLeft(true);
        });
        let arr = [...chatMsg]
        socket.on("message", async (data) => {
            arr.push(data)
            let msg = [...arr]
            setChatMsg(msg)

        });
    }

    useEffect(() => {
        readmsg()
    }, []);

    useEffect(() => {
        // 👇️ scroll to bottom every time messages change
        lastMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [chatMsg]);

    return (
        <div>
            <Card className='chatBodyCard'>
                <Card.Body>
                    <div className='cht-body'>
                        {chatMsg.map((item, index) => {
                            return (
                                <div style={{ marginBottom: "10px" }} key={index}>
                                    {chatMsg[index - 1]?.sender == item.sender ? null : <p style={{ textAlign: item?.sender == "user" ? "left" : "right", marginBottom: "0rem", color: "rgb(156 146 146)" }}>{item?.sender == "user" ? "User" : "You"}</p>}
                                    <div key={index} className={item?.sender == "user" ? 'message__recipient' : 'message__sender'} >
                                        <p>{item?.message}</p>
                                    </div>
                                    <div ref={lastMessageRef} />
                                </div>

                            )
                        })
                        }
                    </div>
                    {userLeft ? <Alert variant="warning">User left the chat ...</Alert> : null}
                    <div className='row card-body d-flex align-items-center'>
                        <Form className="col-lg-11 col-md-10" onSubmit={(e) => handleMessage(e)}>
                            <Form.Group className="" controlId="formBasicEmail">
                                <Form.Control
                                    style={{ borderRadius: "10px" }}
                                    as="textarea"
                                    rows={2}
                                    placeholder="Type a message..."
                                    value={message}
                                    disabled={disableInput}
                                    onChange={(e) => setMessage(e.target.value)}
                                />
                            </Form.Group>
                        </Form>
                        {!disableInput ? <div style={{ justifyContent: "center", display: "flex", background: "red", height: "35px", width: "35px", borderRadius: "50%" }} onClick={(e) => handleMessage(e)}>
                            <SendIcon fontSize="small" style={{ color: "#fff", alignSelf: "center" }} />
                        </div> : null}
                        <div />
                    </div>
                </Card.Body>
            </Card>
        </div>
    )
}

export default memo(ChatBody)
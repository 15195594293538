// import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./components/auth/Login";
import ForgotPassword from "./components/auth/ForgotPassword";
import { Provider } from "react-redux";
import { store } from "./redux/store/Store";
import Body from "./components/view";
import { Alert } from "react-bootstrap";
import UserLiveStream from "./components/view/pannelComponents/liveStream/userLiveStream";
import ViewStream from "./components/view/pannelComponents/liveStream/viewStream";
import DeleteAccount from "./components/auth/DeleteAccount";
import UpdatePassword from "./common-components/updatePassword";

function App() {
  if (window.innerWidth < 767 && window.location.pathname !== "/deleteAccount") {
    return (
      <Alert variant="warning">
        This website is not for mobile. Please view it in desktop/laptop.
      </Alert>
    )
  }
  return (
    <>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Login />} exact />
            <Route path="/deleteAccount" element={<DeleteAccount/>}/>
            <Route path="/userLiveStream" element={<UserLiveStream />} exact />
            <Route path="viewStream" element={<ViewStream/>} />
            <Route path="/home/*" element={<Body />} exact />
            <Route path="/forgotPassword" element={<ForgotPassword/>}/>
            <Route path="/changePassword" element={<UpdatePassword/>}/>
          </Routes>
        </BrowserRouter>

        {/* <PersistGate persistor={persistor}></PersistGate> */}
      </Provider>
    </>
  );
}

export default App;

import { TYPES } from "../../types/types";

// Action Handlers
export const videoListRequest = () => ({
  type: TYPES.VIDEO_REQUEST,
  payload: null,
});

export const videoListSuccess = () => ({
  type: TYPES.VIDEO_SUCCESS,
  payload: null,
});

export const videoListError = (error) => ({
  type: TYPES.VIDEO_ERROR,
  payload: { error },
});



import React from 'react'
import { Button, Form, Offcanvas } from 'react-bootstrap';
import "../../../../css/complaintCanvas.css"

const PoliceComplaintCanvas = ({ data, show, title, setShow }) => {
    const handleClose = () => setShow(false);
    return (
        <>
            <div>
                <Offcanvas show={show} onHide={handleClose}>
                    <Offcanvas.Header className="titleDetails" closeButton>
                        <Offcanvas.Title>
                            {`${title} Complaint Form Details`}
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Form>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <p className="labelPara">Complainant's Name :</p>
                                <Form.Control
                                    readOnly
                                    value={data?.complaintname}>
                                </Form.Control>
                                <p className="labelPara">Complainant's Email :</p>
                                <Form.Control
                                    readOnly
                                    value={data?.email}>
                                </Form.Control>
                                <p className="labelPara">Complainant's Contact No. :</p>
                                <Form.Control
                                    readOnly
                                    value={data?.phoneNumber}>
                                </Form.Control>
                                <p className="labelPara">Complainant's Zip Code :</p>
                                <Form.Control
                                    readOnly
                                    value={data?.zip?data?.zip:"-"}>
                                </Form.Control>
                                <p className="labelPara">Name of Police Department :</p>
                                <Form.Control
                                    readOnly
                                    value={data?.policeDepartment?data?.policeDepartment:"-"}>
                                </Form.Control>
                                <p className="labelPara">
                                    Police Department's Zip Code :
                                </p>
                                <Form.Control
                                    readonly
                                    value={data?.policeDeptZip ? data?.policeDeptZip : "-"}
                                >
                                </Form.Control>
                                <p className="labelPara">Time of Police Interaction :</p>
                                <Form.Control
                                    readOnly
                                    value={data?.timeOfInteraction?data?.timeOfInteraction:"-"}>
                                </Form.Control>
                                <p className="labelPara">
                                    Arrested :
                                </p>
                                <Form.Control
                                    readonly
                                    value={data?.arrested == true? "Arrested" : "Not arrested"}
                                >
                                </Form.Control>
                                <p className="labelPara">Primary Reason for Complaint :</p>
                                <Form.Control
                                    readOnly
                                    value={data?.PRC?data?.PRC:"-"}>
                                </Form.Control>
                                <p className="labelPara">Offences :</p>
                                <Form.Control
                                    readOnly
                                    value={data?.offences?.length ?data?.offences?.length:"-"}>
                                </Form.Control>
                                <p className="labelPara">Race of Police Officer :</p>
                                <Form.Control
                                    readOnly
                                    value={data?.rob? data?.rob : "-"}>
                                </Form.Control>
                                <p className="labelPara">
                                    Officer's Name or Badge Number :
                                </p>
                                <Form.Control
                                    readonly
                                    value={data?.officerNameOrbadge ? data?.officerNameOrbadge : "-"}
                                >
                                </Form.Control>
                                <p className="labelPara">
                                    Complainant's Age :
                                </p>
                                <Form.Control
                                    readonly
                                    value={data?.yourAge ? data?.yourAge : "-"}
                                >
                                </Form.Control>
                                <p className="labelPara">
                                    Complainant's Race :
                                </p>
                                <Form.Control
                                    readonly
                                    value={data?.yourRace ? data?.yourRace : "-"}
                                >
                                </Form.Control>
                                <p className="labelPara">
                                    State :
                                </p>
                                <Form.Control
                                    rows={3} readonly
                                    value={data?.state ? data?.state : "-"}
                                >
                                </Form.Control>
                                <p className="labelPara">
                                    Description :
                                </p>
                                <Form.Control
                                    as="textarea"
                                    rows={3} readonly
                                    value={data?.des ? data?.des : "-"}
                                >
                                </Form.Control>
                                <Button style={{marginTop:"15px"}}
                                 disabled = {!data?.file}
                                >
                                    <a style={{color : "#fff",textDecoration :"none"}} target="_blank" href={data?.file}>Open File</a>
                                </Button>
                            </Form.Group>
                        </Form>
                    </Offcanvas.Body>
                </Offcanvas>
            </div>
        </>

    )
}

export default PoliceComplaintCanvas;
export const TYPES = {
  LOGIN: "LOGIN",
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_ERROR: "LOGIN_ERROR",
  SIGNUP: "SIGNUP",
  SIGNUP_REQUEST: "SIGNUP_REQUEST",
  SIGNUP_SUCCESS: "SIGNUP_SUCCESS",
  SIGNUP_ERROR: "SIGNUP_ERROR",
  DASHBOARD: "DASHBOARD",
  DASHBOARD_REQUEST: "DASHBOARD_REQUEST",
  DASHBOARD_SUCCESS: "DASHBOARD_SUCCESS",
  DASHBOARD_ERROR: "DASHBOARD_ERROR",
  CLEAR_STORE: "CLEAR_STORE",
  VIDEO: "VIDEO",
  VIDEO_REQUEST: "VIDEO_REQUEST",
  VIDEO_SUCCESS: "VIDEO_SUCCESS",
  VIDEO_ERROR: "VIDEO_ERROR",
  AUDIO: "AUDIO",
  AUDIO_REQUEST: "AUDIO_REQUEST",
  AUDIO_SUCCESS: "AUDIO_SUCCESS",
  AUDIO_ERROR: "AUDIO_ERROR",
  DROP: "DROP",
  DROP_REQUEST: "DROP_REQUEST",
  DROP_SUCCESS: "DROP_SUCCESS",
  DROP_ERROR: "DROP_ERROR",
  JUDGE_COMPLAINTS: "JUDGE_COMPLAINTS",
  JUDGE_COMPLAINTS_REQUEST: "JUDGE_COMPLAINTS_REQUEST",
  JUDGE_COMPLAINTS_SUCCESS: "JUDGE_COMPLAINTS_SUCCESS",
  JUDGE_COMPLAINTS_ERROR: "JUDGE_COMPLAINTS_ERROR",
  LAWYER_COMPLAINTS: "LAWYER_COMPLAINTS",
  LAWYER_COMPLAINTS_REQUEST: "LAWYER_COMPLAINTS_REQUEST",
  LAWYER_COMPLAINTS_SUCCESS: "LAWYER_COMPLAINTS_SUCCESS",
  LAWYER_COMPLAINTS_ERROR: "LAWYER_COMPLAINTS_ERROR",
  POLICE_COMPLAINTS: "POLICE_COMPLAINTS",
  POLICE_COMPLAINTS_REQUEST: "POLICE_COMPLAINTS_REQUEST",
  POLICE_COMPLAINTS_SUCCESS: "POLICE_COMPLAINTS_SUCCESS",
  POLICE_COMPLAINTS_ERROR: "POLICE_COMPLAINTS_ERROR",
  SUBSCRIPTION : "SUBSCRIPTION",
  SUBSCRIPTION_REQUEST : "SUBSCRIPTION_REQUEST",
  SUBSCRIPTION_SUCCESS : "SUBSCRIPTION_SUCCESS",
  SUBSCRIPTION_ERROR : "SUBSCRIPTION_ERROR",
};

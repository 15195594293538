
import { useRef, useState } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import "../../css/Sidebar.css";
import GridViewIcon from '@mui/icons-material/GridView';
import LiveTvOutlinedIcon from '@mui/icons-material/LiveTvOutlined';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import Logo from '../assets/Logo.png';
import { useDispatch, useSelector } from 'react-redux';
import SettingsIcon from '@mui/icons-material/Settings';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import { getUser } from '../../redux/selectors/userSelector';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import QueueOutlinedIcon from '@mui/icons-material/QueueOutlined';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import SubscriptionsOutlinedIcon from '@mui/icons-material/SubscriptionsOutlined';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { Logout } from '../../redux/actions/userActions/userActions';


const Sidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);
  const [ChangePassModalShow, setChangePassModalShow] = useState(false);
  const ref = useRef();
  const adminProfileDetails = useSelector(getUser);
  const [open, setOpen] = useState(false);
  const [menuData, setmenuData] = useState([
    {
      id: "dashboard",
      label: "Dashboard",
      icon: <GridViewIcon className="icon" />,
      action: () => {
        navigate("/home/dashboard");
      },
    },
    {
      id: "fileManagement",
      label: "Media",
      icon: <FolderOpenIcon className="icon" />,
      action: () => {
        navigate("/home/fileManagement");
      },
    },
    {
      id: "dropDownManagement",
      label: "Dropdowns",
      icon: <QueueOutlinedIcon className="icon" />,
      action: () => {
        navigate("/home/dropDownManagement");
      },
    },
    {
      id: "complaintManagement",
      label: "Complaints",
      icon: <NoteAddOutlinedIcon className="icon" />,
      action: () => {
        navigate("/home/complaintManagement");
      },
    },
    {
      id: "subscriptionManagement",
      label: "Subscription",
      icon: <SubscriptionsOutlinedIcon className="icon" />,
      action: () => {
        navigate("/home/subscriptionManagement");
      },
    },
    {
      id: "chatUsersList",
      label: "Chat Users",
      icon: <ChatBubbleOutlineIcon className="icon" />,
      action: () => {
        navigate("/home/chatUsersList");
      },
    },
    {
      id: "liveStream",
      label: "Live Stream",
      icon: <LiveTvOutlinedIcon className="icon" />,
      action: () => {
        navigate("/home/liveStream");
      },
    },

  ]);
  
  return (
    <>
      <div className='appLogoBar'>
        <img style={{ marginLeft: "30%" }} width={"100px"} alt='logo' src={Logo} />
        <br />
        <br />
        <h5 style={{ textAlign: "center" }}><PersonIcon color="disabled" fontSize="medium"/> {adminProfileDetails?.username}</h5>
        <h6 className="detailsHeading">{adminProfileDetails?.email}</h6>
        <h6 className="changePassword" onClick={() => navigate("/changePassword")}>Change Password</h6>
      </div>
      <div style={{ marginTop: "10px" }} className="menuListContainer">
        {menuData.map((data, index) => {
          let id = window.location.href.split('/');
          return (
            <ListGroup className='menuListItem' key={index}>
              <ListGroup.Item style={{ backgroundColor: data.id == id[id.length - 1] ? "#148FD6" : "transparent", color: data.id == id[id.length - 1] ? "#fff" : "#000" }} className="menuListItem" onClick={data.action}>{data.icon}  {data.label}</ListGroup.Item>
            </ListGroup>

          )
        })}
        <Button
          className='logoutBtn'
          onClick={() => dispatch(
            Logout(() => {
              navigate("/");
            }))}><b><LogoutIcon />  Logout</b>
        </Button>
      </div>
    </>
  );
}

export default Sidebar;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.subsTableCard{
    width: 100%;
    box-shadow: 2px 2px 4px #ddd;
    margin-bottom: 20px !important;
}

@media only screen and (min-width: 768px) {
    .subsTableCard{
        width:50%;
    }
    .cardContainer{
        justify-content: center;
        display: flex;
    }
}

@media only screen and (min-width: 768px){
    .subsTableCard{
        width:100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/css/subscriptionManagement.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,4BAA4B;IAC5B,8BAA8B;AAClC;;AAEA;IACI;QACI,SAAS;IACb;IACA;QACI,uBAAuB;QACvB,aAAa;IACjB;AACJ;;AAEA;IACI;QACI,UAAU;IACd;AACJ","sourcesContent":[".subsTableCard{\n    width: 100%;\n    box-shadow: 2px 2px 4px #ddd;\n    margin-bottom: 20px !important;\n}\n\n@media only screen and (min-width: 768px) {\n    .subsTableCard{\n        width:50%;\n    }\n    .cardContainer{\n        justify-content: center;\n        display: flex;\n    }\n}\n\n@media only screen and (min-width: 768px){\n    .subsTableCard{\n        width:100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

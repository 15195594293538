// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.streamCard {
    box-shadow: rgb(145 158 171 / 20%) 0px 3px 1px -2px, rgb(145 158 171 / 14%) 0px 2px 2px 0px, rgb(145 158 171 / 12%) 0px 1px 5px 0px;
    margin-bottom: 20px !important;
    background-color: #fff !important;
}


@media screen and (max-width:1365px ) and (min-width: 1024px) {
    .liveVideo{
        width: 35.4%;
    /* height: 45%; */
    overflow: hidden;
        }
         svg.closeIcon{
            position: absolute;
    right: 0;
    top: 0;
        }
}

@media screen and (max-width:1439px ) and (min-width: 1366px){
    .liveVideo{
        width: 27%;
    /* height: 45%; */
    overflow: hidden;
        }
         svg.closeIcon{
            position: absolute;
    right: 0;
    top: 0;
        }
}

@media screen and (min-width:1440px){
    .liveVideo{
        width: 35%;
    /* height: 45%; */
    overflow: hidden;
        }
         svg.closeIcon{
            position: absolute;
    right: 0;
    top: 0;
        }
}


@media screen and (max-width:2048px ) and (min-width: 1441px){
    .liveVideo{
        width: 34.2%;
    /* height: 45%; */
    overflow: hidden;
        }
         svg.closeIcon{
            position: absolute;
    right: 0;
    top: 0;
        }
}




`, "",{"version":3,"sources":["webpack://./src/css/liveStream.css"],"names":[],"mappings":"AAAA;IACI,mIAAmI;IACnI,8BAA8B;IAC9B,iCAAiC;AACrC;;;AAGA;IACI;QACI,YAAY;IAChB,iBAAiB;IACjB,gBAAgB;QACZ;SACC;YACG,kBAAkB;IAC1B,QAAQ;IACR,MAAM;QACF;AACR;;AAEA;IACI;QACI,UAAU;IACd,iBAAiB;IACjB,gBAAgB;QACZ;SACC;YACG,kBAAkB;IAC1B,QAAQ;IACR,MAAM;QACF;AACR;;AAEA;IACI;QACI,UAAU;IACd,iBAAiB;IACjB,gBAAgB;QACZ;SACC;YACG,kBAAkB;IAC1B,QAAQ;IACR,MAAM;QACF;AACR;;;AAGA;IACI;QACI,YAAY;IAChB,iBAAiB;IACjB,gBAAgB;QACZ;SACC;YACG,kBAAkB;IAC1B,QAAQ;IACR,MAAM;QACF;AACR","sourcesContent":[".streamCard {\n    box-shadow: rgb(145 158 171 / 20%) 0px 3px 1px -2px, rgb(145 158 171 / 14%) 0px 2px 2px 0px, rgb(145 158 171 / 12%) 0px 1px 5px 0px;\n    margin-bottom: 20px !important;\n    background-color: #fff !important;\n}\n\n\n@media screen and (max-width:1365px ) and (min-width: 1024px) {\n    .liveVideo{\n        width: 35.4%;\n    /* height: 45%; */\n    overflow: hidden;\n        }\n         svg.closeIcon{\n            position: absolute;\n    right: 0;\n    top: 0;\n        }\n}\n\n@media screen and (max-width:1439px ) and (min-width: 1366px){\n    .liveVideo{\n        width: 27%;\n    /* height: 45%; */\n    overflow: hidden;\n        }\n         svg.closeIcon{\n            position: absolute;\n    right: 0;\n    top: 0;\n        }\n}\n\n@media screen and (min-width:1440px){\n    .liveVideo{\n        width: 35%;\n    /* height: 45%; */\n    overflow: hidden;\n        }\n         svg.closeIcon{\n            position: absolute;\n    right: 0;\n    top: 0;\n        }\n}\n\n\n@media screen and (max-width:2048px ) and (min-width: 1441px){\n    .liveVideo{\n        width: 34.2%;\n    /* height: 45%; */\n    overflow: hidden;\n        }\n         svg.closeIcon{\n            position: absolute;\n    right: 0;\n    top: 0;\n        }\n}\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from 'react'
import { Card, Tab, Tabs } from 'react-bootstrap';
import JudgeComplaints from './judgeComplaints';
import LawyerComplaints from './lawyerComplaints';
import PoliceComplaints from './policeComplaints';
import "../../../../css/complaintManagement.css";

const ComplaintManagement = () => {
    return (
        <div className='complaintCard'>
            <h4> Complaints </h4>
                    <Tabs
                        defaultActiveKey="Judge"
                        id="justify-tab-example"
                        className="mb-3"
                        justify
                    >
                        <Tab
                            eventKey="Judge"
                            title="Judge">
                            <JudgeComplaints />
                        </Tab>
                        <Tab eventKey="Lawyer"
                            title="Lawyer">
                            <LawyerComplaints />
                        </Tab>
                        <Tab eventKey="Police"
                            title="Police">
                            <PoliceComplaints />
                        </Tab>
                    </Tabs>
        </div>

    )
}

export default ComplaintManagement;
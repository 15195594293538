import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Modal, Row } from 'react-bootstrap';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import { getAllVideos } from '../../../../redux/actions/userActions/userActions';
import "../../../../css/videoManagement.css";
import "../../../../css/pagination.css"
import { isLoadingSelector } from '../../../../redux/selectors/statusSelector';
import { errorSelector } from '../../../../redux/selectors/errorSelector';
import { TYPES } from '../../../../redux/types/types';
import Loader from '../../../../common-components/Loader';
import Toaster from '../../../../common-components/Toaster';
import { DELETE, GET } from '../../../../axios/Axios';
import { EndPoints } from '../../../../axios/Endpoints';
import ConfirmationModal from '../../../../common-components/confirmationModal';
import PaginationComponent from '../../../../common-components/Pagination';

const VideoManagement = () => {
    const [videoData, setVideoData] = useState({allVideos:[],videoCount:0});
    const [modalShowDelte, setModalShowDelte] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [modalShow, setModalShow] = useState(false);
    const handleClose = () => setModalShow(false);
    const handleVideoDelClose = () => setModalShowDelte(false);
    const handleShow = () => setModalShow(true);
    const [selectedVid, setselectedVid] = useState(null)
    const [Toster, setToster] = useState(false);
    const [params, setParams] = useState({
        "page": 1,
        "limit": 10,
      });

    const isLoading = useSelector((state) =>
        isLoadingSelector([TYPES.VIDEO], state)
    );
    const errors = useSelector(
        (state) => errorSelector([TYPES.VIDEO], state),
        shallowEqual
    );

    // const errorsProfile = useSelector(
    //     (state) => errorSelector([TYPES.VIDEO], state),
    //     shallowEqual
    // );
    const handleId = async(id)=>{
        let arr = await [...videoData.allVideos];
        let index = await arr.findIndex(item => item._id == id);
        arr[index].isApproved = true;
        setVideoData({...videoData,allVideos:arr});
    }

    const handleApprove = async (id) => {
        const { endpoint, header } = EndPoints.approvedVideos;
        let status = await GET(endpoint, header, { vid: id });
        const { res, error } = status;
        if (res.status == 200) {
            setModalShow(false);
            setToster(true)
            handleId(id);
        } else {
            console.log("error", error);
        }
    }
    const handleDelteVideo = async (id) => {
        const { endpoint, header } = EndPoints.deleteVideo;
        let status = await DELETE(endpoint, header, { vid: id });
        const { res, error } = status;
        if (res.status == 200) {
            setModalShowDelte(false);
            setToster(true)
            handleId(id);
            dispatch(getAllVideos({ setVideoData, params }));
        } else {
            console.log("error", error);
        }
    }
    const paramsChange = async (key, value) => {
        let obj = { ...params };
        obj[key] = value;
        // if(key=="search")obj["page"] = 1;
        setVideoData([]);
        dispatch(getAllVideos({setVideoData, params:obj}));
        setParams(obj);
      };

    useEffect(() => {
        dispatch(getAllVideos({ setVideoData, params }));
    }, []);
    return (
        <div>
            <Row className="videoCardContainer">
                <Loader show={isLoading} />
                <>
                    {
                        isLoading?null:videoData.allVideos.map((item, index) => {
                            return (
                                <Col className='vidCol' md={8} lg={6} xxl={4}>
                                    <Card key={index} className="videoCard">
                                        <p style ={{alignSelf:"flex-start",background: "rgb(0,0,0,0.25)",width: "100%",position: "absolute",paddingLeft:"5px"}}>{item?.uploadedBy}</p>
                                        <video controls style={{ width: '100%', height: "150px" }}>
                                            <source src={item?.videoUrl} type="video/mp4" />
                                            <track kind="captions" label="english_captions" />
                                        </video>
                                        <Card.Body style={{ justifyContent: "space-between", display: "flex",alignItems:"center",    padding: "10px 5px",
                                     }}>
                                            <div>
                                                <p style={{marginBottom: "0"}}> Likes : {item?.likesCount}</p>
                                            </div>
                                            <div>
                                                <p style={{marginBottom: "0"}}> Views : {item?.viewsCount}</p>
                                            </div>
                                            <Button
                                                size="sm"
                                                className="videoCardBtn"
                                                onClick={() => {
                                                    setModalShow(true);
                                                    setselectedVid(item?._id)
                                                }}
                                                disabled={item?.isApproved == true}
                                            >
                                                {item?.isApproved ? "Approved" : "Approve"}
                                            </Button>
                                            <div>
                                                <DeleteIcon style={{color: "red", cursor: "pointer"}} onClick={() => {setModalShowDelte(true);setselectedVid(item._id)} }/>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            )
                        }
                        )

                    }
                    <ConfirmationModal show={modalShowDelte}
                        msg={"Do you want to delete this video ?"}
                        onHide={() => setModalShowDelte(false)}
                        handleClose={handleVideoDelClose}
                        performAction={() => { handleDelteVideo(selectedVid) }}
                    />
                    <ConfirmationModal show={modalShow}
                        msg={"Do you want to approve this video ?"}
                        onHide={() => setModalShow(false)}
                        handleClose={handleClose}
                        performAction={() => { handleApprove(selectedVid) }}
                    />
                </>
                {errors[0] ? (
                    <Toaster bg="danger" msg={errors[0]} position={"bottom-end"} setToster={Toster} hideToster={() => setToster(false)} />
                ) : null}

                {/* {errorsProfile[0] ? (
                    <Toaster
                        bg="danger"
                        msg={errorsProfile[0]}
                        position={"bottom-end"}
                    />
                ) : null} */}
            </Row>
                <div className='paginationContainer'>
      <PaginationComponent paramsChange={paramsChange.bind(this)} params={params} totalCount={videoData?.videoCount} />
      </div>
        </div>
    )
}

export default VideoManagement
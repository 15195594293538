import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Row, Table } from 'react-bootstrap';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import "../../../../css/complaintManagement.css";
import "../../../../css/pagination.css"
import { getJudgeComplaints, getPoliceComplaints } from '../../../../redux/actions/userActions/userActions';
import { TYPES } from '../../../../redux/types/types';
import JudgeComplaintCanvas from './judgeComplaintCanvas';
import { isLoadingSelector } from '../../../../redux/selectors/statusSelector';
import Loader from '../../../../common-components/Loader';
import { EndPoints } from '../../../../axios/Endpoints';
import { GET, PATCH } from '../../../../axios/Axios';
import ConfirmationModal from '../../../../common-components/confirmationModal';
import { errorSelector } from '../../../../redux/selectors/errorSelector';
import Toaster from '../../../../common-components/Toaster';
import PoliceComplaintCanvas from './policeComplaintCanvas';
import PaginationComponent from '../../../../common-components/Pagination';

const PoliceComplaints = () => {
  const [policeData, setPoliceData] = useState({policeComplaints:[],policeComplaintCount:0});
  const [selectedItem, setSelectedItem] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const handleClose = () => setModalShow(false);
  const [Toster, setToster] = useState(false);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [params, setParams] = useState({
    "page": 1,
    "limit": 10,
  })
  

  const isLoading = useSelector((state) =>
    isLoadingSelector([TYPES.POLICE_COMPLAINTS], state)
  );

  const errors = useSelector(
    (state) => errorSelector([TYPES.POLICE_COMPLAINTS], state),
    shallowEqual
  );

  const handleShow = (item) => {
    setShow(true)
    setSelectedItem(item);
  };

  const handleApprove = async (_id) => {
    const { endpoint, header } = EndPoints.approveComplaints;
    // debugger
    let status = await PATCH(endpoint,{complaintId: _id},header);
    const { res, error } = status;
    if (res.status == 200) {
      setModalShow(false);
      setToster(true);
    dispatch(getPoliceComplaints({ setPoliceData, params }));
    } else {
      console.log("error", error);
    }
  }

  const paramsChange = async (key, value) => {
    let obj = { ...params };
    obj[key] = value;
    // if(key=="search")obj["page"] = 1;
    dispatch(getPoliceComplaints({setPoliceData, params:obj}));
    setParams(obj);
  };

  useEffect(() => {
    dispatch(getPoliceComplaints({ setPoliceData, params }));
  }, []);

  return (
    <>
      <div>
        <Row>
          <Col md={12} lg={12}>
            <Card className="policeCard">
              <Card.Body className="table-responsive">
                <Table hover bordered  size="sm">
                  <thead>
                    <tr>
                      <th className="tableHeader">Complainant's Name</th>
                      <th className="tableHeader">Complainant's Email</th>
                      <th className="tableHeader">Complainant's Contact No.</th>
                      <th className="tableHeader">Complainant's Address</th>
                      <th className="tableHeader">Name of Police Department</th>
                      <th className="tableHeader">Time of Police Interaction</th>
                      <th className="tableHeader">Actions</th>
                    </tr>
                  </thead>
                  <Loader show={isLoading} />
                  {policeData?.policeComplaints.map((item, index) => {
                    return (
                      <tbody key={index}>
                        <tr>
                          <td>{item?.complaintname ? item?.complaintname : "-"}</td>
                          <td>{item?.email ? item?.email : "-"}</td>
                          <td>{item?.phoneNumber ? item?.phoneNumber : "-"}</td>
                          <td><strong>Address</strong> &nbsp; <br/> {item?.address?item?.address:"-"} <br/><br/> <strong>City </strong> &nbsp; {item?.city ? item?.city : "-"}<br/><br/> <strong>State </strong> &nbsp; {item?.state ? item?.state : "-"} <br/><br/>
                          <strong>Zipcode</strong> &nbsp;{item?.zip ? item?.zip : "-"}</td>
                          <td>{item?.policeDepartment ? item?.policeDepartment : "-"}</td>
                          <td>{item?.timeOfInteraction ? item?.timeOfInteraction : "-"}</td>
                          <td className="actiontd">
                            <div style={{ justifyContent: "center", alignSelf: "center" }}>
                              <span style={{ color: "#0D99FF",fontSize: "14px", textDecoration: "none", cursor: "pointer" }}
                                onClick={() => { handleShow(item) }}
                              >View more</span></div>
                            <div>
                              <Button variant="success"
                              className='approveBtn'
                                size="sm"
                                onClick={() => {
                                  setModalShow(true);
                                  setSelectedItem(item?._id)
                                }}
                                disabled={item?.aprooved == true}
                              >
                                {item?.aprooved == true? "Approved" : "Approve"}
                              </Button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    )
                  })}
                </Table>
              </Card.Body>
            </Card>
          </Col>
          <PoliceComplaintCanvas show={show} title={"Police"} setShow={setShow} data={selectedItem} />
          <ConfirmationModal show={modalShow}
            msg={"Do you want to approve this complaint ?"}
            onHide={() => setModalShow(false)}
            handleClose={handleClose}
            performAction={() => {handleApprove(selectedItem)}}
          />
          {errors[0] ? (
            <Toaster bg="danger" msg={errors[0]} position={"bottom-end"} setToster={Toster} hideToster={() => setToster(false)} />
          ) : null}
        </Row>
      </div>
      <div className='paginationContainer'>
      <PaginationComponent paramsChange={paramsChange.bind(this)} params={params} totalCount={policeData?.policeComplaintCount} />
      </div>
    </>
  )
}

export default PoliceComplaints
import React, { useEffect, useState } from "react";
import AnimatedNumber from "animated-number-react";
const AnimatedNum = ({value,style}) => {
  const [num, setNum] = useState(value?value:0);
  const formatValue = (num) => num.toFixed(0);
    return (
      <div>
        <AnimatedNumber
          style={style}
          value={value?value:0}
          formatValue={formatValue}
        />
      </div>
    );
  }
export default AnimatedNum;
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chatCard{
    box-shadow: rgb(145 158 171 / 20%) 0px 3px 1px -2px, rgb(145 158 171 / 14%) 0px 2px 2px 0px, rgb(145 158 171 / 12%) 0px 1px 5px 0px;
margin-bottom: 20px !important;
background-color: #fff;
}

.chatSidebar{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    margin-right: 0 !important;
    margin-left: 0 !important;
    padding-left: 0px !important;
    /* background-color: #151528 !important; */
    background-color: #fff;
    box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
    /* border: 2px Solid #f8c537; */
    border-top: none;
    min-height: 600px;
    }

  `, "",{"version":3,"sources":["webpack://./src/css/chatUserList.css"],"names":[],"mappings":"AAAA;IACI,mIAAmI;AACvI,8BAA8B;AAC9B,sBAAsB;AACtB;;AAEA;IACI,eAAe;IACf,MAAM;IACN,SAAS;IACT,OAAO;IACP,0BAA0B;IAC1B,yBAAyB;IACzB,4BAA4B;IAC5B,0CAA0C;IAC1C,sBAAsB;IACtB,uDAAuD;IACvD,+BAA+B;IAC/B,gBAAgB;IAChB,iBAAiB;IACjB","sourcesContent":[".chatCard{\n    box-shadow: rgb(145 158 171 / 20%) 0px 3px 1px -2px, rgb(145 158 171 / 14%) 0px 2px 2px 0px, rgb(145 158 171 / 12%) 0px 1px 5px 0px;\nmargin-bottom: 20px !important;\nbackground-color: #fff;\n}\n\n.chatSidebar{\n    position: fixed;\n    top: 0;\n    bottom: 0;\n    left: 0;\n    margin-right: 0 !important;\n    margin-left: 0 !important;\n    padding-left: 0px !important;\n    /* background-color: #151528 !important; */\n    background-color: #fff;\n    box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);\n    /* border: 2px Solid #f8c537; */\n    border-top: none;\n    min-height: 600px;\n    }\n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

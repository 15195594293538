export const EndPoints = {
  login: {
    endpoint: "user/adminlogin",
  },
  dashboard :{
    endpoint :"user/dashboard",
    header: { accessToken: true},
  },
  videoList : {
    endpoint : "video/getAllvideos",
    header: { 
      accessToken: true,
       media: true },
  },
  audioList : {
    endpoint : "video/getAllAudios",
    header: { accessToken: true, media: true },
  },
  approvedVideos:{
    endpoint : "admin/videoManage/approveVideo",
    header: { accessToken: true},
  },
  dropDowns : {
    endpoint : "admin/dropdowns/getDropdowns",
    header: { accessToken: true},
  },
  addDropDowns : {
    endpoint : "admin/dropdowns/addDropdowns",
    header : {accessToken: true},
  },
  judgeComplaints : {
    endpoint : "admin/complaints/getJudgeComplaints",
    header : {accessToken : true},
  },
  lawyerComplaints : {
    endpoint : "admin/complaints/getLawyerComplaints",
    header : {accessToken : true},
  },
  policeComplaints  : {
    endpoint : "admin/complaints/getPoliceComplaints",
    header : {accessToken : true},
  },
  approveComplaints : {
    endpoint : "admin/complaints/aprooveComplaint",
    header : {accessToken : true},
  },
  lawyerComplaints : {
    endpoint : "admin/complaints/getLawyerComplaints",
    header : {accessToken : true},
  },
  subscription:{
    endpoint : "subscription/getAllSubscriptions",
    header : {accessToken : true},
  },
  deleteVideo:{
    endpoint: "admin/videoManage/deleteVideo",
    header: {accessToken : true}
  },
  forgotPassword:{
    endpoint: "user/forgotPassword"
  },
  resetPassword:{
    endpoint: "user/resetpassword"
  }
};

import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Pannel from "./Pannel";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../../css/Home.css";
import { setRef } from "../../axios/Axios";
import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import { useDispatch } from "react-redux";
import UpdatePassword from "../../common-components/updatePassword";
const Body = () => {
  const navigate = useNavigate()
  useEffect(() => {
    if(!localStorage.getItem("Token"))
    {
      navigate("/")
    }

  }, [])

  return (
    <Container fluid style={{ height: "100%" }}>
      <Row style={{ height: "100vh" }}>
        <Col md={3} lg={2} xl={2} xxl={2} style={{paddingRight:"0px"}} className="sidebar-container">
          <Sidebar />
        </Col>
        <Col md={9} lg={10} xl={10} xxl={10} className="main-container">
          <Row>
            <div >
              <Pannel/>
              {/* <UpdatePassword/>   */} 
            </div>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Body;

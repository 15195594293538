import { GET, POST } from "../../../axios/Axios";
import { EndPoints } from "../../../axios/Endpoints";
import { audioListError, audioListRequest, audioListSuccess } from "./audioActions";
import { complaintError, complaintRequest, complaintSuccess, judgeComplaintError, judgeComplaintRequest, judgeComplaintSuccess } from "./judgeActions";
import { dropError, dropRequest, dropSuccess } from "./dropActions";
import { clearStore, loginError, loginRequest, loginSuccess } from "./loginActions";
import { videoListError, videoListRequest, videoListSuccess } from "./videoActions";
import { policeComplaintError, policeComplaintRequest, policeComplaintSuccess } from "./poilceActions";
import { lawyerComplaintError, lawyerComplaintRequest, lawyerComplaintSuccess } from "./lawyerActions";
import { subscriptionError, subscriptionRequest, subscriptionSuccess } from "./subscriptionActions";
import { dashboardError, dashboardRequest, dashboardSuccess } from "./dashboardActions";


export const clearError = () => async (dispatch) => {
  dispatch(loginError(""));
};

export const userLogin = (data, callback) => async (dispatch) => {
  dispatch(loginRequest());
  try {
    const { endpoint } = EndPoints.login;
    const Status = await POST(endpoint, data);
    const { res, error } = Status;
    if (res) {
      callback();
      localStorage.setItem("Token", res.data.user.token);
      dispatch(loginSuccess(res.data.user));
    } else {
      dispatch(loginError(error.message));
    }
  } catch (error) {
    dispatch(loginError(error.message));
  }
};

export const Logout = (e) => async (dispatch) => {
  dispatch(clearStore());
  localStorage.clear();
  e();
};


export const getDashboardData =({setDashboardData}) => async (dispatch) => {
  dispatch(dashboardRequest());
  try {
    const { endpoint, header } = EndPoints.dashboard;

    let status = await GET(endpoint, header);
    const { res, error } = status;
    if (res) {
      dispatch(dashboardSuccess());
      setDashboardData(res.data);
    } else {
      dispatch(dashboardError(error.message));
    }
  } catch (error) {
    dispatch(dashboardError("Oops! Something went wrong"));
  }
}

export const getAllVideos = ({setVideoData, params}) => async (dispatch) => {
  dispatch(videoListRequest());
  try {
    const { endpoint, header } = EndPoints.videoList;

    let status = await GET(endpoint, header, params);
    const { res, error } = status;
    if (res) {
      dispatch(videoListSuccess());
      setVideoData(res.data);
    } else {
      dispatch(videoListError(error.message));
    }
  } catch (error) {
    dispatch(videoListError("Oops! Something went wrong"));
  }
}

export const getAllAudios = ({setAudioData, params}) => async (dispatch) => {
  dispatch(audioListRequest());
  try {
    const { endpoint, header } = EndPoints.audioList;

    let status = await GET(endpoint, header, params);
    const { res, error } = status;
    if (res) {
      dispatch(audioListSuccess());
      setAudioData(res.data);
    } else {
      dispatch(audioListError(error.message));
    }
  } catch (error) {
    dispatch(audioListError("Oops! Something went wrong"));
  }
}

export const getAllDropDown = ({ setdropData }) => async (dispatch) => {
  dispatch(dropRequest());
  try {
    const { endpoint, header } = EndPoints.dropDowns;

    let status = await GET(endpoint, header);
    const { res, error } = status;
    if (res) {
      dispatch(dropSuccess());
      setdropData(res.data.data[0]);
    } else {
      dispatch(dropError(error.message));
    }
  } catch (error) {
    dispatch(dropError("Oops! Something went wrong"));
  }
}

export const getJudgeComplaints = ({ setJudgeData, params }) => async (dispatch) => {
  dispatch(judgeComplaintRequest());
  try {
    const { endpoint, header } = EndPoints.judgeComplaints;

    let status = await GET(endpoint, header, params);
    const { res, error } = status;
    if (res) {
      dispatch(judgeComplaintSuccess());
      setJudgeData(res.data);
    } else {
      dispatch(judgeComplaintError(error.message));
    }
  } catch (error) {
    dispatch(judgeComplaintError("Oops! Something went wrong"));
  }
}

export const getPoliceComplaints = ({ setPoliceData, params }) => async (dispatch) => {
  dispatch(policeComplaintRequest());
  try {
    const { endpoint, header } = EndPoints.policeComplaints;

    let status = await GET(endpoint, header, params);
    const { res, error } = status;
    if (res) {
      dispatch(policeComplaintSuccess());
      setPoliceData(res.data);
    } else {
      dispatch(policeComplaintError(error.message));
    }
  } catch (error) {
    dispatch(policeComplaintError("Oops! Something went wrong"));
  }
}

export const getLawyerComplaints = ({ setLawyerData, params }) => async (dispatch) => {
  dispatch(lawyerComplaintRequest());
  try {
    const { endpoint, header } = EndPoints.lawyerComplaints;

    let status = await GET(endpoint, header, params);
    const { res, error } = status;
    if (res) {
      dispatch(lawyerComplaintSuccess());
      setLawyerData(res.data);
    } else {
      dispatch(lawyerComplaintError(error.message));
    }
  } catch (error) {
    dispatch(lawyerComplaintError("Oops! Something went wrong"));
  }
}

export const getAllSubscriptions =({setPlansData}) => async (dispatch) => {
  dispatch(subscriptionRequest());
  try {
    const { endpoint, header } = EndPoints.subscription;

    let status = await GET(endpoint, header);
    const { res, error } = status;
    if (res) {
      dispatch(subscriptionSuccess());
      setPlansData(res.data.plans);
    } else {
      dispatch(subscriptionError(error.message));
    }
  } catch (error) {
    dispatch(subscriptionError("Oops! Something went wrong"));
  }
}

import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Container, Form, ListGroup, Row } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch } from 'react-redux';
import { GET, POST } from '../../../axios/Axios';
import { EndPoints } from '../../../axios/Endpoints';
import CloseIcon from '@mui/icons-material/Close';
import "../../../css/dropdown.css";
import { getAllDropDown } from '../../../redux/actions/userActions/userActions';
import Toaster from '../../../common-components/Toaster';

const DropDownManagement = () => {
    const dispatch = useDispatch();
    const [dropData, setdropData] = useState({});
    const [selectedItem, setSelectedItem] = useState("");
    const [inputValue, setInputValue] = useState("");
    const [Toster, setToster] = useState(false);

    const handleSelect = (e) => {
        setSelectedItem(Object.keys(dropData)[e]);
    }

    const UpdateDropDown = async (obj) => {
        const { endpoint, header } = EndPoints.addDropDowns;
        let status = await POST(endpoint, obj, header);
        const { res, error } = status;
        if (res) {
            setInputValue("");
            // alert(res.data.message);
            console.log(inputValue);
        } else {
            console.log("res.message", res.data.message)
        }
    }
    const addDropdown = async (e) => {
        e.preventDefault();
        let obj = { ...dropData }
        let array = [...obj[selectedItem]]
        array.push({ value: inputValue })
        obj[selectedItem] = array
        setdropData(obj)
        UpdateDropDown(obj)
        setToster(true);
    }

    const deleteListItem = (value) => {
        let obj = { ...dropData }
        let arr = [...obj[selectedItem]]
        let index = arr.findIndex((item) => item?.value == value);
        arr.splice(index, 1);
        obj[selectedItem] = arr
        setdropData(obj);
        console.log("dropData",obj[selectedItem]);
        UpdateDropDown(obj)
    }

    useEffect(() => {
        dispatch(getAllDropDown({ setdropData }));
    }, [])
    return (
        <>
            <Container fluid>
                <Row>
                    <div className='dropContainer'>
                        <Col md={{ span: 4, offset: 1 }} lg={4} style={{ alignItems: "flex-start" }}>
                            <Dropdown className="drop" onSelect={handleSelect} >
                                <Dropdown.Toggle style={{ textAlign: "left",paddingLeft:"0px" }} id="dropdown-basic">
                                    {selectedItem ? selectedItem : "Select Category"}
                                    {" "}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <p style={{ color: "Blue", marginLeft: "100px", textDecoration: "underline", cursor: "pointer" }} onClick={() => setSelectedItem("")}>Clear</p>
                                    {dropData && Object?.keys(dropData)?.length ? Object?.keys(dropData)?.map((item, index) => {
                                        return (
                                            <>
                                                <Dropdown.Item
                                                    eventKey={index}
                                                    key={index}
                                                >{item}
                                                </Dropdown.Item>
                                            </>
                                        )
                                    })
                                    :null
                                    }
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                        <Col md={{ span: 6, offset: 1 }} lg={4}>
                            {selectedItem ?
                                <Form style={{ justifyContent: "center", display: "flex" }}>
                                    <div>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Control
                                                className='inputDrop'
                                                type="text"
                                                placeholder="Add Dropdown"
                                                value={inputValue}
                                                onChange={(e) => {
                                                    setInputValue(e.target.value);
                                                }}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div style={{ marginLeft: "10px" }}>
                                        <Button className="addBtn"
                                            variant="primary"
                                            type="submit"
                                            onClick={(e) => { addDropdown(e) }}
                                            disabled={!inputValue.length > 0}
                                        >
                                            ADD
                                        </Button>
                                    </div>
                                </Form> :
                                null
                            }

                            {(selectedItem ? dropData[selectedItem].length!==0 : false)  ?
                                <Card className="addDropMenu">
                                    <Card.Body>
                                        {dropData[selectedItem].map((item, index) => {
                                            return (
                                                <ListGroup key={index} >
                                                    <ListGroup.Item style={{ background: "#fff", justifyContent: "space-between", display: "flex" }}>{item.value}{" "} <CloseIcon style={{ cursor: "pointer" }} onClick={() => { deleteListItem(item.value) }} /></ListGroup.Item>
                                                </ListGroup>
                                            )
                                        })}
                                    </Card.Body>
                                </Card>
                                : null}
                        </Col>
                    </div>
                </Row>
                <Toaster bg="success" msg={`This is successfully added in ${selectedItem}`} position={"bottom-end"} setToster={Toster} hideToster={() => setToster(false)} />
            </Container>

        </>
    )
}

export default DropDownManagement
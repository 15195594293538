
import React, { useEffect, useRef, useState } from 'react'
import {v4 as uuid} from "uuid"
import { Card, Modal } from 'react-bootstrap';
import Peer from "peerjs";
import CloseIcon from '@mui/icons-material/Close';
import { getUser } from '../../../../redux/selectors/userSelector';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import "../../../../css/liveStream.css"



const ViewStream = ({ socket }) => {
  const videoRef = useRef(null)
  const params = useLocation();
  const navigate = useNavigate();
  const user = useSelector(getUser);

  const callPeer = (stream) => {
    return new Promise((resolve, reject) => {
      let peer = new Peer(uuid());
      setTimeout(() => {
        let call = peer.call(params.state.selectedPeerId, stream)
        resolve(call)
      }, 1000);
    })
  }

  const gotMedia = async (stream) => {
    let call = await callPeer(stream)
    // peer.on("close", () => {
    //   alert("peer connection closed")
    // })
    call.on("stream", (remoteStream) => {
      console.log({remoteStream}, {getVideoTracks: remoteStream.getVideoTracks()}, {getAudioTracks: remoteStream.getAudioTracks()});
      if (!videoRef.current) {
        alert("video not available")
      } else {
        // videoRef.srcObject = remoteStream;
        // videoRef.play()
        let video = videoRef.current;
        video.srcObject = remoteStream;
        // video.play();
      }
      // debugger
    }, (err) => {

      console.log("errrrr", err)
    })
    // });
  }
  const onClose = async () => {
    // peer.disconnect()
    // peer.destroy();
    navigate("/home/liveStream");
    debugger
  };
  
  const createEmptyVideoTrack = ({ width, height }) => {
    const canvas = Object.assign(document.createElement('canvas'), { width, height });
    canvas.getContext('2d').fillRect(0, 0, width, height);

    // const stream = canvas.captureStream();
    // const track = stream.getVideoTracks()[0];
    const videoStream = canvas.captureStream();
    const videoTrack = videoStream.getVideoTracks()[0];

    const audioContext = new AudioContext();
    const audioStream = audioContext.createMediaStreamDestination().stream;
    const audioTrack = audioStream.getAudioTracks()[0];

    const combinedStream = new MediaStream([videoTrack, audioTrack]);

    return Object.assign(combinedStream, { enabled: false });
  };

  useEffect(() => {
    if(global.socket)
   { 
    const videoTrack = createEmptyVideoTrack({ width: "34%"})
    // const stream = new MediaStream([videoTrack]);
    gotMedia(videoTrack);
    global.socket.on('userLeftVideoRoom', () => {navigate('/home/liveStream')})
    global.socket.emit('adminJoinVideoRoom',{roomId:params.state.selectedRoomId
    })
  } else{
    navigate("/home/liveStream");
  }
  },[]);

  const windowHeight = window.innerHeight;
  const windowWidth = window.innerWidth;

  return (
    <>
      <Card style={{ background: "#000", borderRadius: "0px" }}>
        <Card.Body style={{justifyContent:"center",display:"flex",width:`${windowWidth}+px`,height:`${windowHeight}px`, color: "red"}}>
        <CloseIcon className="closeIcon" fontSize="large" style={{ color: "#fff", alignSelf: "flex-end", marginRight: "10px", cursor: "pointer" }} onClick={() => onClose()} />
          <video className="liveVideo" ref={videoRef} autoPlay muted={false}></video>
        </Card.Body>
      </Card>
    </>
  )

}


export default ViewStream
import React, { memo, useEffect, useRef, useState } from 'react';
import { Card, Col, Modal, Row } from 'react-bootstrap';
import ChatNavbar from './chatNavbar';
import ChatBody from './chatBody';

const ChatModal = ({ show, setShow, fullscreen ,socket,selectedRoomId,clientName}) => {
    return (

        <Modal className="chatModal" show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
            <Row>
                <ChatNavbar setShow={setShow} socket={socket} selectedRoomId={selectedRoomId} clientName={clientName}/>
            </Row>
            <Row style={{ justifyContent: "center" }}>
                <Col md={8} lg={6}>
                    <ChatBody socket={socket} selectedRoomId={selectedRoomId} />
                </Col>
            </Row>
        </Modal >
    )
}

export default memo(ChatModal);
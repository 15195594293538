// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.totalUsers svg{
font-size:80px;
color: #148fd6;
}
.totalUsers .card-title {
    font-size:25px;
}
.totalUsers .card-text{
    font-size:25px;
    color: #148fd6;
    font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/css/dashboard.css"],"names":[],"mappings":"AAAA;AACA,cAAc;AACd,cAAc;AACd;AACA;IACI,cAAc;AAClB;AACA;IACI,cAAc;IACd,cAAc;IACd,gBAAgB;AACpB","sourcesContent":[".totalUsers svg{\nfont-size:80px;\ncolor: #148fd6;\n}\n.totalUsers .card-title {\n    font-size:25px;\n}\n.totalUsers .card-text{\n    font-size:25px;\n    color: #148fd6;\n    font-weight: 600;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.forgot-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow: hidden; /* Prevent scrolling */
}

.forgotPasswordCard {
    width: 35rem;
    margin: auto;
    border-radius: 0.75rem !important;
    border: none;
}

.heading {
    color: #000;
    text-align: left;
    margin-top: 20px;
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    font-weight: 700px !important;
}

.forgotPassword-input {
    margin-top: 10px;
    width: 100% !important;
    height: 50px;
    border-radius: 10px;
    border: 1px solid #CCDDE8 !important;
    margin-bottom: 1rem;
}

.back-icon{
    cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/css/ForgotPassword.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,gBAAgB,EAAE,sBAAsB;AAC5C;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,iCAAiC;IACjC,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,gBAAgB;IAChB,gBAAgB;IAChB,iBAAiB;IACjB,qBAAqB;IACrB,6BAA6B;AACjC;;AAEA;IACI,gBAAgB;IAChB,sBAAsB;IACtB,YAAY;IACZ,mBAAmB;IACnB,oCAAoC;IACpC,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".forgot-password-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n    overflow: hidden; /* Prevent scrolling */\n}\n\n.forgotPasswordCard {\n    width: 35rem;\n    margin: auto;\n    border-radius: 0.75rem !important;\n    border: none;\n}\n\n.heading {\n    color: #000;\n    text-align: left;\n    margin-top: 20px;\n    font-size: 1.5rem;\n    margin-bottom: 1.5rem;\n    font-weight: 700px !important;\n}\n\n.forgotPassword-input {\n    margin-top: 10px;\n    width: 100% !important;\n    height: 50px;\n    border-radius: 10px;\n    border: 1px solid #CCDDE8 !important;\n    margin-bottom: 1rem;\n}\n\n.back-icon{\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .modal-content>#loader {
    background: transparent !important;
} */

.Loader-modal .modal-content {
    background-color: transparent !important;
    border: none;
}
.Loader-modal .modal-dialog {
    
    text-align: center !important;
}`, "",{"version":3,"sources":["webpack://./src/css/loader.css"],"names":[],"mappings":"AAAA;;GAEG;;AAEH;IACI,wCAAwC;IACxC,YAAY;AAChB;AACA;;IAEI,6BAA6B;AACjC","sourcesContent":["/* .modal-content>#loader {\n    background: transparent !important;\n} */\n\n.Loader-modal .modal-content {\n    background-color: transparent !important;\n    border: none;\n}\n.Loader-modal .modal-dialog {\n    \n    text-align: center !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fileCard {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    /* border: none !important; */
    box-shadow: rgb(145 158 171 / 20%) 0px 3px 1px -2px, rgb(145 158 171 / 14%) 0px 2px 2px 0px, rgb(145 158 171 / 12%) 0px 1px 5px 0px;
    padding: 70px 0;
  }
  
  .fileCard .nav-item button{font-weight: 500; color:#000}
  .fileCard .nav-item button.nav-link.active{color:#f00}

  @media screen and (max-width: 1439px) and (min-width: 1025px){
.fileCard {
    height: 590px !important
  }
  }

  @media only screen and (min-width: 1440px){
    .fileCard{
        height: 700px !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/css/fileManagement.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,iBAAiB;IACjB,oBAAoB;IACpB,6BAA6B;IAC7B,mIAAmI;IACnI,eAAe;EACjB;;EAEA,2BAA2B,gBAAgB,EAAE,UAAU;EACvD,2CAA2C,UAAU;;EAErD;AACF;IACI;EACF;EACA;;EAEA;IACE;QACI,wBAAwB;IAC5B;AACJ","sourcesContent":[".fileCard {\n    width: 100%;\n    padding-top: 20px;\n    padding-bottom: 20px;\n    /* border: none !important; */\n    box-shadow: rgb(145 158 171 / 20%) 0px 3px 1px -2px, rgb(145 158 171 / 14%) 0px 2px 2px 0px, rgb(145 158 171 / 12%) 0px 1px 5px 0px;\n    padding: 70px 0;\n  }\n  \n  .fileCard .nav-item button{font-weight: 500; color:#000}\n  .fileCard .nav-item button.nav-link.active{color:#f00}\n\n  @media screen and (max-width: 1439px) and (min-width: 1025px){\n.fileCard {\n    height: 590px !important\n  }\n  }\n\n  @media only screen and (min-width: 1440px){\n    .fileCard{\n        height: 700px !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

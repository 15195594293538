import React from 'react'
import { Button, Container, Navbar } from 'react-bootstrap';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';

const ChatNavbar = ({ setShow, socket,selectedRoomId,clientName}) => {
  const handleLeave = () => {
    socket.emit("adminLeaveRoom",{roomId:selectedRoomId})
    setShow(false)
  }

  return (
    <div>
      <Navbar bg="dark" variant="dark">
        <Container style={{justifyContent:'flex-start'}} fluid >
          <Navbar.Brand>Chat</Navbar.Brand>
        <div style={{marginLeft:"275px",justifyContent:"center",display:"flex",alignItems:"center"}}>
          <PersonOutlineIcon style={{color:"#fff"}}/>
          <p style={{color:"#fff",alignSelf:"center",marginBottom:"0rem",marginLeft:"10px"}}>{clientName}</p>
        </div>
        </Container>
                    <Button className='leaveBtn' onClick={() => handleLeave()}>Leave Chat</Button>
      </Navbar>
    </div>
  )
}

export default ChatNavbar
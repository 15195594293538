import React, { useState } from "react";
import "../../css/ForgotPassword.css";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router-dom";
import { PATCH } from "../../axios/Axios";
import { EndPoints } from "../../axios/Endpoints";
import Toaster from "../../common-components/Toaster";
import Loader from "../../common-components/Loader";

const ForgotPassword = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState('')
  const [toaster, setToaster] = useState(false);
  const [loaderState, setLoaderState] = useState(false);

  const validator = (type, value) => {
    switch (type) {
      case "email":
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
      default:
        return false;
    }
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    // Validate email
    if (!value) {
      setError("Email cannot be empty");
    } else if (!validator("email", value)) {
      setError("Please enter a valid email address");
    } else {
      setError("");
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoaderState(true)
    const forgotPassword_endpoint = EndPoints.forgotPassword.endpoint;
    const response = await PATCH( forgotPassword_endpoint,{ email }, { "Content-Type": "application/json" });
  
    if (!response.res) {
      setError(response.error.message ?? `${response.error.message}`);
    }  else {
      setSuccess("A password reset link has been sent to your email.");
      setEmail("");
      setToaster(true);
      setTimeout(() => {
        navigate("/");
      }, 3000);
    }
    setLoaderState(false)
  };
  

  const isFormValid = email && !error;

  return (
    <div className="forgot-password-container">
      <Toaster msg={success} bg="success" setToster={toaster} position="top-end"/>
      <Loader show={loaderState}/>
      <Card className="forgotPasswordCard">
        <Card.Body>
          <ArrowBackIcon className="back-icon" onClick={() => navigate("/")} />
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <h2 className="heading">
                Enter your email here to change password
              </h2>
              <Form.Control
                className="forgotPassword-input"
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={handleEmailChange}
                style={{
                  border: error ? "2px solid red" : "2px solid #EDECE8",
                }}
              />
              {error && (
                <span style={{ color: "red", display: "block", marginTop: "5px" }}>
                  {error}
                </span>
              )}
            </Form.Group>

            <Button
              variant="primary"
              type="submit"
              disabled={!isFormValid}
              style={{
                width: "100%",
                borderRadius: "0.5rem",
                backgroundColor: !isFormValid ? "#CCDDE8" : "#007bff",
                color: !isFormValid ? "#000" : "#fff",
                border: "none",
              }}
            >
              SEND
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ForgotPassword;

import React from "react";
import { Card, Tab, Tabs } from "react-bootstrap";
import "../../../../css/fileManagement.css";
import VideoManagement from "./videoManagement";
import AudioManagement from "./audioManagement";
const Filemanagement = () => {
    return (
        <div>
            <Card className="fileCard">
                <Card.Title style={{ marginTop: "-60px", textAlign: "center" }}><h4> Media </h4></Card.Title>
                <Card.Body>
                    <Tabs
                        defaultActiveKey="videos"
                        id="justify-tab-example"
                        className="mb-3"
                        justify
                    >
                        <Tab
                         eventKey="videos" 
                         title="Videos">
                            <VideoManagement/>
                        </Tab>
                        <Tab eventKey="audios" 
                        title="Audios">
                            <AudioManagement/>
                        </Tab>
                    </Tabs>
                </Card.Body>
            </Card>
        </div>
    );
}
export default Filemanagement;
import React, { createRef, useEffect, useRef, useState } from 'react'
import { Button, Table } from 'react-bootstrap';
import { Peer } from "peerjs";
import socketIO from "socket.io-client";
import { Host } from '../../../../axios/Host';
import storage from 'redux-persist/lib/storage';
import moment from "moment/moment";
import "../../../../css/liveStream.css"
import ViewStream from './viewStream';
import Loader from '../../../../common-components/Loader';
import { useNavigate, useSearchParams } from 'react-router-dom';


const StreamUserList = () => {

  const peer = new Peer();
  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [queryParameters] = useSearchParams();
  const navigate = useNavigate();
  const [searchId,setSearchId] = useState(null);

  let [socket, setSocket] = useState(null)
  const socket_open = async () => {
    let Token = await storage.getItem("Token");
    console.log("Token",Token);
    socket = await socketIO("https://iwitness.policeabuse.com", {
      extraHeaders: {
        "x-access-token": Token
      }
    });
    getInfo(socket)
  }
  const getInfo = async (socket) => {
    setSocket(socket)
    socket.emit('getAllVideoRooms', {});
    socket.on('allVideoRooms', (data) => {
      setIsLoading(false);
      setUserData(data);
    }
    );
  }

  useEffect(() => {
    const querryRoomId = queryParameters.get("roomId");
    setSearchId(querryRoomId);
    socket_open();
  }, [])
  useEffect(() => {
    global.socket = socket
  }, [socket])

  const openVideoStream = (selectedRoomId, selectedPeerId) => {
    navigate("/viewStream", {state: {selectedRoomId, selectedPeerId}})
  }
  return (
    <div className='streamCard'>
      { userData.length ?
      <Table bordered hover>
        <thead>
          <tr>
            <th>User</th>
            <th>Requested Time</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          { userData.length ? userData.map((item, index) => {
            return (
              <tr key={index} style={searchId == item?.roomId ?{background:"#ff000045"}:{}}>
                <td>{item?.clientName}</td>
                <td>{moment(item.createdAt).format("MM-DD-YYYY HH:mm:ss")}</td>
                <td>
                  <Button size="sm"
                    style={{ color: "#fff", background: "red", border: "none", cursor: "pointer" }}
                    onClick={() => openVideoStream(item.roomId, item.peerId)}
                  >Show Live stream</Button>
                </td>
              </tr>
            )
          })
            : null
            }
          <Loader show={isLoading} />
        </tbody>
      </Table>
      : <p style={{ color: "red",fontWeight:"600",fontSize:"18px",paddingLeft:"10px",textAlign:"center" }}>Looks like you have no request available for now .</p>}
    </div>
  )
}

export default StreamUserList
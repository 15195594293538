import { header } from "./Headers";
import axios from "axios";
import { Logout } from "../redux/actions/userActions/userActions";

let dispatch = null;
let navigate = null;

export const setRef = (Refdispatch,Refnavigate) => {
  navigate = Refnavigate;
  dispatch = Refdispatch;
  return null;
}

export const GET = async (url, headers, params) => {
  let h = await header({ ...headers });
  var data;
  try {
    await axios
      .get(url, {
        params,
        headers: h,
      })
      .then((res) => {
        data = { res, error: false };
      })
      .catch((error) => {
        if (error?.response?.status == 401) {
          dispatch(Logout(()=>navigate("/")));
          data = { error: error?.response?.data, res: false };
        } else {
          data = { error: error?.response?.data, res: false };
        }
      });
  } catch (error) {
    data = { error: error?.response?.data, res: false };
  }
  return data;
};

export const POST = async (url, Body, headers) => {
  var data;
  let h = await header({ ...headers });
  const config = {
    headers: h,
  };
  try {
    const body = JSON.stringify(Body);
    await axios
      .post(url, body, config)
      .then((res) => {
        data = { res, error: false };
      })
      .catch((error) => {
        if (error?.response?.status == 401) {
          dispatch(Logout(()=>navigate("/")));
          data = { error: error?.response?.data, res: false };
        } else {
          data = { error: error?.response?.data, res: false };
        }
      });
  } catch (error) {
    data = { error: error?.response?.data, res: false };
  }
  return data;
};

export const DELETE = async (url, headers, params) => {
  let h = await header({ ...headers });
  var data;

  try {
    await axios
      .delete(url, {
        params,
        headers: h,
      })
      .then((res) => {
        data = { res, error: false };
      })
      .catch((error) => {
        if (error?.response?.status == 401) {
          dispatch(Logout(()=>navigate("/")));
          data = { error: error?.response?.data, res: false };
        } else {
          data = { error: error?.response?.data, res: false };
        }
      });
  } catch (error) {
    data = { error: error?.response?.data, res: false };
  }

  return data;
};
export const PATCH = async (url, Body, headers) => {
  var data;
  let h = await header({ ...headers });
  const config = {
    headers: h,
  };
  try {
    const body = JSON.stringify(Body);
    await axios
      .patch(url, body, config)
      .then((res) => {
        data = { res, error: false };
      })
      .catch((error) => {
        if (error?.response?.status == 401) {
          dispatch(Logout(()=>navigate("/")));
          data = { error: error?.response?.data, res: false };
        } else {
          data = { error: error?.response?.data, res: false };
        }
      });
  } catch (error) {
    data = { error: error?.response?.data, res: false };
  }
  return data;
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.titleDetails{
    box-shadow: rgb(145 158 171 / 20%) 0px 3px 1px -2px, rgb(145 158 171 / 14%) 0px 2px 2px 0px, rgb(145 158 171 / 12%) 0px 1px 5px 0px;
color : #595252
}

.labelPara{
    margin-bottom: 0rem !important;
    margin-top: 10px !important;
    font-weight: 500;
}
`, "",{"version":3,"sources":["webpack://./src/css/complaintCanvas.css"],"names":[],"mappings":"AAAA;IACI,mIAAmI;AACvI;AACA;;AAEA;IACI,8BAA8B;IAC9B,2BAA2B;IAC3B,gBAAgB;AACpB","sourcesContent":[".titleDetails{\n    box-shadow: rgb(145 158 171 / 20%) 0px 3px 1px -2px, rgb(145 158 171 / 14%) 0px 2px 2px 0px, rgb(145 158 171 / 12%) 0px 1px 5px 0px;\ncolor : #595252\n}\n\n.labelPara{\n    margin-bottom: 0rem !important;\n    margin-top: 10px !important;\n    font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { TYPES } from "../../types/types";

// Action Handlers
export const audioListRequest = () => ({
  type: TYPES.AUDIO_REQUEST,
  payload: null,
});

export const audioListSuccess = () => ({
  type: TYPES.AUDIO_SUCCESS,
  payload: null,
});

export const audioListError = (error) => ({
  type: TYPES.AUDIO_ERROR,
  payload: { error },
});


